import { Button, MenuTriggerChildProps, Tooltip, makeStyles, shorthands  } from '@fluentui/react-components';
import { Add20Filled, Add20Regular } from '@fluentui/react-icons';
import React from 'react';
import { customColors, customFonts } from '../../../../styles';

const useClasses = makeStyles({
    smallContainer: {
        ...shorthands.borderRadius('50%'),
        display: 'flex',
        flexDirection: 'row',
    },
    smallDisabled: {
        ...shorthands.borderRadius('50%'),
        width: '35px',
        height: '35px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'transparent',
        cursor: 'not-allowed',
    },
    smallEnabled: {
        ...shorthands.borderRadius('50%'),
        width: '35px',
        height: '35px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: customColors.protivitiDarkBlue,
        '&:hover': {
            cursor: 'pointer',
        },
    },
    normal: {
        display: 'flex',
        alignItems: 'center',
        fontSize: '15px',
        fontWeight: '700',
        fontFamily: customFonts.Lato,
        // justifyContent: 'space-between'
    },
    textWrapper: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        justifyContent: 'space-between',
    },
});

interface CustomMenuTriggerProps extends Partial<MenuTriggerChildProps> {
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    viewportWidth: number;
    thresholdPixelValue: number;
    chatCount: number;
    ALLOWEDCHATS: number;
    width: number;
}

export const AddChatButton = React.forwardRef<HTMLButtonElement, CustomMenuTriggerProps>(
    ({ show, setShow, viewportWidth, thresholdPixelValue, chatCount, ALLOWEDCHATS, width }, ref) => {
        const classes = useClasses();

        return (
            <div style={{ zIndex: '100' }}>
                {viewportWidth <= thresholdPixelValue ? (
                    <div className={classes.smallContainer} style={{ transition: 'all 0.3s ease' }}>
                        {chatCount >= ALLOWEDCHATS ? (
                            <Tooltip content="Remove a chat to create a new chat!" relationship="label">
                                <button
                                    disabled={true}
                                    className={classes.smallDisabled}
                                    style={{ transition: 'all 0.3s ease', border: '2px solid #AAAAAA', color: 'white' }}
                                    ref={ref}
                                >
                                    <Add20Filled />
                                </button>
                            </Tooltip>
                        ) : (
                            <button
                                onClick={() => {
                                    setShow(!show);
                                }}
                                className={classes.smallEnabled}
                                style={{
                                    transition: 'all 0.3s ease',
                                    border: show ? '2px solid transparent' : '2px solid white',
                                    backgroundColor: show ? customColors.summarizeButtonDarker : customColors.white,
                                    color: show ? customColors.white : customColors.darkNavy,
                                }}
                                ref={ref}
                            >
                                <Add20Filled />
                            </button>
                        )}
                    </div>
                ) : (
                    <div style={{ transition: 'all 0.3s ease' }}>
                        {chatCount >= ALLOWEDCHATS ? (
                            <Tooltip content="Remove a chat to create a new chat!" relationship="label">
                                <Button
                                    disabled={true}
                                    data-testid="createNewConversationButton"
                                    appearance="transparent"
                                    className={classes.normal}
                                    style={{
                                        padding: '10px 20px 10px 14px',
                                        transition: 'all 0.3s ease',
                                        backgroundColor: customColors.white,
                                        color: 'rgba(170, 170, 170, 0.75)',
                                        width: `${width - 132}px`,
                                    }}
                                    ref={ref}
                                >
                                    <div className={classes.textWrapper}>
                                    {(!show) && <Add20Filled />} 
                                    {<span > {chatCount}/{ALLOWEDCHATS} </span>}
                                        <span> New Chat </span>
                                </div>
                                
                            </Button>
                    </Tooltip>
                    ) : (
                        <Button
                            disabled={chatCount >= ALLOWEDCHATS ? true : false}
                            onMouseEnter={() => {setShow(true)}}
                            // onMouseLeave={() => {setHover(false)}}
                            // onClick={handleClick}
                            data-testid="createNewConversationButton"
                            appearance="transparent"
                            className={classes.normal}
                                    style={{
                                        // padding: ((chatCount >= ALLOWEDCHATS) || show) ? '10px 20px 10px 14px' : '10px 0px 10px 14px',
                                        padding: '10px 10px 10px 10px',
                                        transition: "all 0.3s ease",
                                        backgroundColor: (show) ? customColors.summarizeButtonDarker : customColors.white,
                                        color: show ? customColors.white : customColors.darkNavy,
                                        width:  `${width - 30}px`,
                            }}
                            ref = {ref}
                            >
                                <div className={classes.textWrapper} style={{paddingRight:'8px'}}>
                                {!show && <Add20Regular />}
                                {show && (
                                        <span>
                                            {' '}
                                            {chatCount}/{ALLOWEDCHATS}{' '}
                                        </span>
                                    )}
                                    <span> New Chat </span>
                                  
                                   
                                </div>
                            </Button>
                        )}
                    </div>
                )}
            </div>
        );
    },
) as React.FC<CustomMenuTriggerProps>;

AddChatButton.displayName = 'AddChatButton';
