import { IFile, IGPT } from '../models/GPT';
import { BaseService } from './BaseService';

export class GPTService extends BaseService {
    public getGPTsAsync = async (accessToken: string): Promise<IGPT[]> => {
        const result = await this.getResponseAsync<IGPT[]>(
            {
                commandPath: `gpts`,
                method: 'GET',
            },
            accessToken,
        );
        return result;
    };
    public getAllGPTsAsync = async (accessToken: string): Promise<IGPT[]> => {
        const result = await this.getResponseAsync<IGPT[]>(
            {
                commandPath: `gpts/all`,
                method: 'GET',
            },
            accessToken,
        );
        return result;
    };
    public getRegisteredGPTsAsync = async (accessToken: string): Promise<IGPT[]> => {
        const result = await this.getResponseAsync<IGPT[]>(
            {
                commandPath: `gpts/registered`,
                method: 'GET',
            },
            accessToken,
        );
        return result;
    };
    public deleteRegisteredGPTsAsync = async (accessToken: string, gptId: string, hardDelete?: boolean): Promise<any> => {
        const result = await this.getResponseAsync<any>(
            {
                commandPath: `gpts/registered/${gptId}${hardDelete ? `?hardDelete=${hardDelete}` : ''}`,
                method: 'DELETE',
            },
            accessToken,
        );
        return result;
    };
    public getSasUrlAsync = async (accessToken: string, blobName: string|undefined): Promise<any> => {
        const result = await this.getResponseAsync<any>(
            {
                commandPath: `api/get-sas-url?blobName=${blobName}`,
                method: 'GET',
            },
            accessToken,
        );
        return result;
    };
    public registerGPTAsync = async (gpt: IGPT, gptIcon: File, files: File[], accessToken: string): Promise<any> => {
        const formData = new FormData();
        const gptJson = JSON.stringify(gpt);
        formData.append('gpt', gptJson);
        formData.append('gptIcon', gptIcon);
        for (const file of files) {
            formData.append('files', file);
        }
        const result = await this.getResponseAsync<IGPT>(
            {
                commandPath: `gpt/register`,
                method: 'POST',
                body: formData,
            },
            accessToken,
        );
        return result;
    };
    public updateGPTAsync = async (gpt: IGPT, gptIcon: File|undefined, newFiles: File[], filesToDelete: IFile[], accessToken: string): Promise<any> => {
        const formData = new FormData();
        const gptJson = JSON.stringify(gpt);
        formData.append('gpt', gptJson);
        if(gptIcon)formData.append('gptIcon', gptIcon);
        for (const file of newFiles) {
            formData.append('files', file);
        }
        for (const file of filesToDelete) {
            formData.append('filesToDelete', file.id)
        }
        
        const result = await this.getResponseAsync<IGPT>(
            {
                commandPath: `gpt/registrationupdate`,
                method: 'PUT',
                body: formData,
            },
            accessToken,
        );

        return result;
    };

    public uploadFileToThread = async (files: File[], threadId: string, accessToken: string): Promise<any> => {
        const formData = new FormData();
        for (const file of files) {
            formData.append('files', file);
        }
        const result = await this.getResponseAsync<any>(
            {
                commandPath: `chatSession/${threadId}`,
                method: 'POST',
                body: formData
            },
            accessToken,
        );
        return result;
    };
}
