import { IChatMessage } from '../../../libs/models/ChatMessage';

// Each generating message will have its own botResponseStatus and the actual IChatMessage
export interface GeneratingMessage {
    chatInput: string;
    isResponseGenerating: boolean;
    botResponseStatus: string;
    message?: IChatMessage;
}

// an object of currently generating messages separated by chatId
export type GeneratingMessages = Record<string, GeneratingMessage>;

// the state of all the currently generating messages
export interface CurrentMessagesState {
    generatingMessages: GeneratingMessages;
}

// the initial state of the currently generating messages (there are none when you start the app)
export const initialState: CurrentMessagesState = {
    generatingMessages: {},
};
