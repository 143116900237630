import { Button, Input } from '@fluentui/react-components';
import { AddCircleRegular, DeleteFilled } from '@fluentui/react-icons';
import { Controller, useFieldArray } from 'react-hook-form';
import { customColors } from '../../styles';

export const NestedItemsFieldArray = ({ control, name, isFaq }: { control: any; name: string; isFaq?: boolean }) => {
    const { fields, append, remove } = useFieldArray({
        control,
        name,
    });

    return (
        <>
            {fields.map((field, index) => (
                <div key={field.id} style={{ marginBottom: '8px', marginRight: '8px' }}>
                    <Controller
                        name={isFaq ? `${name}.${index}.question` : `${name}.${index}.name`}
                        control={control}
                        render={({ field }) => (
                            <Input
                                {...field}
                                style={{
                                    borderRadius: 0,
                                    marginRight: '8px',
                                    border: `2px solid ${customColors.headerLightGray}`,
                                }}
                                placeholder={isFaq ? 'Insert question' : 'Insert name'}
                            />
                        )}
                    />
                    <Controller
                        name={isFaq ? `${name}.${index}.answer` : `${name}.${index}.description`}
                        control={control}
                        render={({ field }) => (
                            <Input
                                {...field}
                                style={{
                                    borderRadius: 0,
                                    marginRight: '8px',
                                    border: `2px solid ${customColors.headerLightGray}`,
                                }}
                                placeholder={isFaq ? 'Insert answer' : 'Insert description'}
                            />
                        )}
                    />
                    {isFaq && (
                        <DeleteFilled
                            type="button"
                            onClick={() => remove(index)} 
                            style={{ fontSize: '30px', color: customColors.headerLightGray, marginLeft: '8px' }}
                        />
                    )}
                </div>
            ))}
            {isFaq && fields.length < 6 && (
                <Button
                    appearance="subtle"
                    icon={<AddCircleRegular />}
                    onClick={() => append({ question: '', answer: '' })}
                    shape="square"
                    style={{ position: 'relative', minWidth: 0, padding: '0px' }}
                >
                    {isFaq ? 'Add FAQ' : 'Add Prompt'}
                </Button>
            )}
        </>
    );
};
