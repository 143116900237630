import { Button } from '@fluentui/react-button';
import { Tooltip, makeStyles, mergeClasses, shorthands } from '@fluentui/react-components';
import {
    Dialog,
    DialogActions,
    DialogBody,
    //DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
} from '@fluentui/react-dialog';
import { ReactComponent as DeleteIcon } from '../../../../assets/Trash.svg';
import { ReactComponent as DeleteDisabledIcon } from '../../../../assets/TrashDisabled.svg';
import { useChat } from '../../../../libs/hooks';
import { useAppSelector } from '../../../../redux/app/hooks';
import { RootState } from '../../../../redux/app/store';
import { FeatureKeys } from '../../../../redux/features/app/AppState';
//import { setSelectedConversation } from '../../../../redux/features/conversations/conversationsSlice';
import { customColors, customFonts } from '../../../../styles';
//import { Delete16 } from '../../../shared/BundledIcons';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { addMessagesToConversation, setConversation, setConversations } from '../../../../redux/features/conversations/conversationsSlice';

const useClasses = makeStyles({
    root: {
        width: '450px',
        borderRadius: '0',
    },
    actions: {
        paddingTop: '10%',
    },
    delete: {
        backgroundColor: customColors.protivitiOrange,
        '&:hover': {
            backgroundColor: customColors.summarizeButtonDarker,
            color: customColors.white,
        },
    },
    close: {
        backgroundColor: customColors.white,
        '&:hover': {
            backgroundColor: customColors.summarizeButtonDarker,
            color: customColors.white,
        },
    },
    button: {
        fontFamily: customFonts.Lato,
        fontSize: '12px',
        borderRadius: '0',
        ...shorthands.border('1.5px', 'solid', customColors.protivitiOrange),
    },
    title: {
        fontFamily: customFonts.TitleFont,
        fontWeight: '500',
        fontSize: '24px',
    },
});

interface IEditChatNameProps {
    chatId: string;
    chatName: string;
}

export const DeleteChatDialog: React.FC<IEditChatNameProps> = ({ chatId, chatName }) => {
    const classes = useClasses();
    const chat = useChat();
    const { conversations, selectedId } = useAppSelector((state: RootState) => state.conversations);
    const { features } = useAppSelector((state: RootState) => state.app);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const onDeleteChat = () => {
        void chat
            .deleteChat(chatId, conversations[chatId]?.gptEndpoint)
            .then(() => {
                const remainingChatIds = Object.keys(conversations).filter((id) => id !== chatId);
                const firstChatId = remainingChatIds[0];
                const { [chatId]: _, ...remainingConversations } = conversations;

                if (firstChatId) {
                    dispatch(setConversations(remainingConversations));
                    if (remainingConversations[firstChatId]) {
                        dispatch(setConversation({ ...remainingConversations[firstChatId], id: firstChatId }));
                        navigate(`/chat/${firstChatId}`);

                        const messages = remainingConversations[firstChatId].messages || [];
                        if (messages.length === 0) {
                            let count = -1;
                            let skip = 0;
                            if (process.env.REACT_APP_DEFAULT_PROGPT === conversations[selectedId].customGPTId) {
                                count = 100;
                                skip = 0;
                            }
                            chat.loadChat(firstChatId, remainingConversations[firstChatId].gptEndpoint, skip, count)
                                .then((succeeded) => {
                                    if (succeeded) {
                                        console.log('Chat messages loaded successfully.');
                                    }
                                })
                                .catch((e: any) => {
                                    const errorMessage = `Unable to get chat messages. Details: ${e instanceof Error ? e.message : String(e)}`;
                                    console.log(errorMessage);
                                });
                        } else {
                            dispatch(addMessagesToConversation({ messages, chatId: firstChatId }));
                        }
                    } else {
                        console.error('First chat ID is not found in remaining conversations');
                    }
                } else {
                    dispatch(setConversations(remainingConversations));
                    navigate('/');
                }
            })
            .catch((error) => {
                console.error('Failed to delete chat:', error);
            });
    };
    return (
        <Dialog modalType="alert">
            <DialogTrigger>
                <Tooltip content={'Delete chat session'} relationship="label">
                    <Button
                        disabled={Object.keys(conversations).length <= 1 || !features[FeatureKeys.DeleteChats].enabled}
                        icon={
                            Object.keys(conversations).length <= 1 || !features[FeatureKeys.DeleteChats].enabled ? (
                                <DeleteDisabledIcon />
                            ) : (
                                <DeleteIcon />
                            )
                        }
                        appearance="transparent"
                        aria-label="delete_chat"
                    />
                </Tooltip>
            </DialogTrigger>
            <DialogSurface className={classes.root}>
                <DialogBody>
                    <DialogTitle className={classes.title}>
                        Are you sure you want to delete chat {chatName}?
                    </DialogTitle>
                    <DialogActions className={classes.actions}>
                        <DialogTrigger action="close" disableButtonEnhancement>
                            <Button
                                style={{ transition: 'all 0.3s ease' }}
                                className={mergeClasses(classes.close, classes.button)}
                                appearance="secondary"
                            >
                                Cancel
                            </Button>
                        </DialogTrigger>
                        <DialogTrigger action="close" disableButtonEnhancement>
                            <Button
                                style={{ transition: 'all 0.3s ease' }}
                                className={mergeClasses(classes.delete, classes.button)}
                                onClick={onDeleteChat}
                            >
                                Delete
                            </Button>
                        </DialogTrigger>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );
};
