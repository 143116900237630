import { useMsal } from '@azure/msal-react';
import { getErrorDetails } from '../../components/utils/TextUtils';
import { useAppSelector } from '../../redux/app/hooks';
import { RootState } from '../../redux/app/store';
import { AuthHelper } from '../auth/AuthHelper';
import { IUserPermission } from '../models/UserPermission';
import { UserPermissionService } from '../services/UserPermissionService';
import { toast } from 'react-toastify';
import { customFonts } from '../../styles';
import { makeStyles } from '@fluentui/react-components';

const useStyles = makeStyles({

    toastMessage: {
        fontSize: '18px',
        color: 'black',
        fontFamily: customFonts.TitleFont,
        backgroundColor: '#F7F7F8',
        border: "1px solid black",
        marginTop: '33px',
    },
});

export const useUserPermission = () => {
    //const dispatch = useDispatch();
    const { instance, inProgress } = useMsal();
    const userPermissionService = new UserPermissionService(process.env.REACT_APP_BACKEND_URI as string);
    const classes = useStyles();

    const { activeUserInfo } = useAppSelector((state: RootState) => state.app);
    const userId = activeUserInfo?.id ?? '';
    const fullName = activeUserInfo?.username ?? '';

    const getADEmployeeAsync = async (nameSearch: string) => {
        try {
            const accessToken = await AuthHelper.getSKaaSAccessToken(instance, inProgress);
            const adEmployees = await userPermissionService.getADEmployeeByNameAsync(accessToken, nameSearch);
            return adEmployees;
        } catch (e: any) {
            const errorMessage = `Unable to get AD Employees. Details: ${getErrorDetails(e)}`;
            toast.error(errorMessage, {
                className: classes.toastMessage,
              });
        }
        return [];
    };

    const getPermissionAsync = async (chatId: string) => {
        try {
            const accessToken = await AuthHelper.getSKaaSAccessToken(instance, inProgress);
            const userPermission = await userPermissionService.getPermissionsAsync(chatId, accessToken);
            return userPermission;
        } catch (e: any) {
            const errorMessage = `Unable to get User Permission. Details: ${getErrorDetails(e)}`;
            toast.error(errorMessage, {
                className: classes.toastMessage,
              });
        }
        return [];
    };

    const createPermissionAsync = async (chatId: string, users: IUserPermission[]) => {
        try {
            const accessToken = await AuthHelper.getSKaaSAccessToken(instance, inProgress);
            const userPermission = await userPermissionService.createPermissionAsync(chatId, users, accessToken);
            return userPermission;
        } catch (e: any) {
            const errorMessage = `Unable to create User Permission. Details: ${getErrorDetails(e)}`;
            toast.error(errorMessage, {
                className: classes.toastMessage,
              });
        }
        return 'Create error';
    };

    const updatePermissionAsync = async (users: IUserPermission[]) => {
        try {
            const accessToken = await AuthHelper.getSKaaSAccessToken(instance, inProgress);
            const userPermission = await userPermissionService.updatePermissionAsync(users, accessToken);
            return userPermission;
        } catch (e: any) {
            const errorMessage = `Unable to update User Permission. Details: ${getErrorDetails(e)}`;
            toast.error(errorMessage, {
                className: classes.toastMessage,
              });
        }
        return 'Update error';
    };

    const deletePermissionAsync = async (users: IUserPermission[]) => {
        try {
            const accessToken = await AuthHelper.getSKaaSAccessToken(instance, inProgress);
            const userPermission = await userPermissionService.deletePermissionAsync(users, accessToken);
            return userPermission;
        } catch (e: any) {
            const errorMessage = `Unable to delete User Permission. Details: ${getErrorDetails(e)}`;
            toast.error(errorMessage, {
                className: classes.toastMessage,
              });
        }
        return 'Delete error';
    };

    const deleteGPTPermissionsAsync = async (gptId: string) => {
        const accessToken = await AuthHelper.getSKaaSAccessToken(instance, inProgress);
        try {
            const userPermission = await userPermissionService.deleteGPTPermissionsAsync(gptId, accessToken);
            return userPermission;
        } catch (e: any) {
            const errorMessage = `Unable to delete User Permission. Details: ${getErrorDetails(e)}`;
            toast.error(errorMessage, {
                className: classes.toastMessage,
              });
        }
        return 'Delete error';
    };

    const addGPTUserPermission = async (userPermission: IUserPermission) => {
        const accessToken = await AuthHelper.getSKaaSAccessToken(instance, inProgress);
        try {
            const permission = await userPermissionService.addGPTUserPermissionAsync(
                userPermission,
                accessToken,
                userId,
                fullName,
            );
            return permission;
        } catch (e: any) {
            const errorMessage = `Unable to add User Permission. Details: ${getErrorDetails(e)}`;
            toast.error(errorMessage, {
                className: classes.toastMessage,
              });
        }
    };

    return {
        getADEmployeeAsync,
        getPermissionAsync,
        createPermissionAsync,
        updatePermissionAsync,
        deletePermissionAsync,
        deleteGPTPermissionsAsync,
        addGPTUserPermission,
    };
};
