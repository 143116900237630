import { createSlice, PayloadAction, Slice } from '@reduxjs/toolkit';
import { CurrentMessagesState, GeneratingMessage, GeneratingMessages, initialState } from './CurrentMessagesState';
import { IChatMessage } from '../../../libs/models/ChatMessage';

export const currentmessagesSlice: Slice<CurrentMessagesState> = createSlice({
    name: 'currentmessages',
    initialState,
    reducers: {
        setGeneratingMessages: (state: CurrentMessagesState, action: PayloadAction<GeneratingMessages>) => {
            state.generatingMessages = action.payload;
        },
        setGeneratingConvo: (
            state: CurrentMessagesState,
            action: PayloadAction<{ chatId: string; contents: GeneratingMessage }>,
        ) => {
            const setId = action.payload.chatId;
            state.generatingMessages[setId] = action.payload.contents;
        },
        updateChatInput: (state: CurrentMessagesState, action: PayloadAction<{ chatId: string; text: string }>) => {
            const { chatId, text } = action.payload;
            state.generatingMessages[chatId].chatInput = text;
        },
        updateIsResponseGenerating: (
            state: CurrentMessagesState,
            action: PayloadAction<{ chatId: string; isResponseGenerating: boolean }>,
        ) => {
            const { chatId, isResponseGenerating } = action.payload;
            state.generatingMessages[chatId].isResponseGenerating = isResponseGenerating;
        },
        updateBotResponseStatus: (
            state: CurrentMessagesState,
            action: PayloadAction<{ chatId: string; status: string }>,
        ) => {
            const { chatId, status } = action.payload;
            if (status !== '' && state.generatingMessages[chatId].isResponseGenerating) {
                state.generatingMessages[chatId].botResponseStatus = status;
            } else if (status === '' && !state.generatingMessages[chatId].isResponseGenerating) {
                // If the incoming status is empty and the response is still ongoing, leave it at the previous status
                state.generatingMessages[chatId].botResponseStatus = status;
            }
        },
        setGeneratingChatMessage: (
            state: CurrentMessagesState,
            action: PayloadAction<{ chatId: string; message: IChatMessage }>,
        ) => {
            const { chatId, message } = action.payload;
            state.generatingMessages[chatId].message = message;
        },
        updateGeneratingMessageContent: (
            state: CurrentMessagesState,
            action: PayloadAction<{ chatId: string; content: string }>,
        ) => {
            const { chatId, content } = action.payload;
            if (state.generatingMessages[chatId] && state.generatingMessages[chatId].message) {
                (state.generatingMessages[chatId].message as { content: string }).content = content;
            }
        },
        removeGeneratingMessage: (state: CurrentMessagesState, action: PayloadAction<{ chatId: string }>) => {
            const { chatId } = action.payload;
            delete state.generatingMessages[chatId].message;
        },
    },
});

export const {
    setGeneratingMessages,
    setGeneratingConvo,
    updateChatInput,
    updateIsResponseGenerating,
    updateBotResponseStatus,
    setGeneratingChatMessage,
    updateGeneratingMessageContent,
    removeGeneratingMessage,
} = currentmessagesSlice.actions;

export default currentmessagesSlice.reducer;
