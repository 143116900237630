import { useEffect, useState } from 'react';
import { UserManagementService } from '../services/UserManagementService';
import { AuthHelper } from '../auth/AuthHelper';
import { useMsal } from '@azure/msal-react';
import {IUser} from '../models/User';

export const useUserManagement = (currentPage: number, itemsPerPage: number, searchTerm: string) => {
  const [users, setUsers] = useState<IUser[]>([]);
  const userManagementService = new UserManagementService(process.env.REACT_APP_BACKEND_URI as string);
  const { instance, inProgress } = useMsal();
const [totalRecords, setTotalRecords] = useState(0);

  useEffect(() => {
    const fetchUsers = async () => {
      const accessToken = await AuthHelper.getSKaaSAccessToken(instance, inProgress); // Get the access token
        const users = await userManagementService.getUsersAsync(accessToken, currentPage, itemsPerPage,searchTerm);
      if (users && Array.isArray(users.items)) {
        setUsers(users.items);
        setTotalRecords(users.totalRecords);
    } else {
        console.error('getUsersAsync did not return an array', users);
        setUsers([]); // Set users to an empty array as a fallback
        setTotalRecords(0);
    }
};
fetchUsers();
}, [currentPage, itemsPerPage, searchTerm]);

    const updateUser = async (userId: string, updatedUser: Partial<IUser>) => {
        const accessToken = await AuthHelper.getSKaaSAccessToken(instance, inProgress);
        await userManagementService.updateUserAsync(updatedUser as IUser, accessToken);
        const updatedUsers = users.map((user) => {
            if (user.id === userId) {
                return { ...user, ...updatedUser };
            } else {
                return user;
            }
        });
        setUsers(updatedUsers);
    };

  return {users,totalRecords,updateUser};
};
