import PptxGenJS from 'pptxgenjs';
import { Component } from 'react';
import protivitiLogo from '../../assets/gl-en.svg';
import { customColors } from '../../styles';
import { HtmlContent } from '../../libs/models/ExportPPTX';



interface ExportUtilProps {
    data: HTMLElement;
}

interface TextObject {
    TextProps: PptxGenJS.TextProps;
    Height: number;
}

const textSlideOptions: PptxGenJS.TextPropsOptions = {
    placeholder: 'body',
    x: 0.25,
    y: 0.1,
    w: '95%',
    h: 4.9,
    align: 'left',
    fontSize: 14,
};

class ExportUtilsPPTX extends Component {
    private HTMLdata: HTMLElement;
    private childNodes: NodeListOf<ChildNode>;
    private result: TextObject[][] = [];
    private arrTextObjs: TextObject[] = [];
    private height = 0;
    //private finalResult: IExportPPTXProps[] = [];
    private htmlArrayforExport:HtmlContent[]=[];
    private pptx = new PptxGenJS();
private lengthOfEachSlide:number=1500;
private slideNo:number=0;
private remainingLength:number=1500;
    constructor(props: ExportUtilProps) {
        super(props);

        this.childNodes = props.data.childNodes;
        this.HTMLdata = props.data;

    }

    createTemplate = () => {
        this.pptx.defineSlideMaster({
            title: 'PLACEHOLDER_SLIDE',
            background: { color: 'FFFFFF' },
            objects: [
                { image: { x: 8.75, y: 5, h: 0.5, w: 151 / 164, path: protivitiLogo } },

                // { placeholder: {
                //     options: { name: "body", type: "body"}
                // }}
            ],
        });
    };

    processData = (childNodes: NodeListOf<ChildNode>) => {
        const result: PptxGenJS.TextProps[][] = [];
let textcontent:string="";
let prevContent:string="";
        for (const child of childNodes) {
            
            this.formatChildNode(child);
        }
        this.result.push(this.arrTextObjs);
        for (const page of this.result) {
            const pageArrTextObjs: PptxGenJS.TextProps[] = [];
            for (const item of page) {
                pageArrTextObjs.push(item.TextProps);
           
            }
            result.push(pageArrTextObjs);

        }
      
        if(this.htmlArrayforExport[this.slideNo] && this.htmlArrayforExport[this.slideNo].slideContent)
            {
                let tempSlideContentLegth=(this.htmlArrayforExport[this.slideNo].slideContent.replace("<span style='font-size:15px;color:black;'>","").replace("<span style='font-size:15px;color:black;font-weight:bold;'>","").replace("</span>","").trim()).length;
                this.remainingLength=this.lengthOfEachSlide-tempSlideContentLegth;
                prevContent=this.htmlArrayforExport[this.slideNo].slideContent;
            }
        result.forEach(element => {
           element.forEach(item=>{           
          
                    if(item.text!=undefined){
                        this.remainingLength=this.remainingLength - (item.options?.color?20:0)
                        
                        if(this.remainingLength>item.text.length){
                            textcontent+=item.options?.breakLine==true?"<br/>":"";
                            this.remainingLength=this.remainingLength-20;
                            textcontent+=prevContent+"<span style='font-size:"+(item.options?.fontSize?item.options?.fontSize:"15px")+";color:"+(item.options?.color?item.options?.color:"black")+";"+(item.options?.bold==true?"font-weight:bold;":"")+"'>"+item.text+"</span>";
                            prevContent="";                         
                        }
                    else{
                       
                        if(textcontent!=''){
                        this.remainingLength=this.lengthOfEachSlide;  
                                             
                        this.htmlArrayforExport[this.slideNo]={slideId:this.slideNo,slideContent:textcontent};
                        this.slideNo++;
                        
                        textcontent =item.options?.breakLine==true?"<br/>":"";
                        this.remainingLength=this.remainingLength-20;
                        textcontent+=prevContent+"<span style='font-size:"+(item.options?.fontSize?item.options?.fontSize:"15px")+";color:"+(item.options?.color?item.options?.color:"black")+";"+(item.options?.bold==true?"font-weight:bold;":"")+"'>"+item.text+"</span>";
                        
                       
                        prevContent="";
                        }
                        else{
                            textcontent=item.options?.breakLine==true?"<br/>":"";
                            this.remainingLength=this.remainingLength-20;
                                textcontent+=prevContent+item.text;
                               
                                prevContent="";

                        }

                        
                    }
                
            this.remainingLength=this.remainingLength-item.text?.length;
            
                }
        
    });
  });
 
  if(textcontent!=''){
  this.htmlArrayforExport[this.slideNo]={slideId:this.slideNo,slideContent:textcontent};
  }
  
  //this.slideNo++;
      //this.finalResult.push({slideNo:1,dataOptions: {breakLine:true,fontSize:14,textLine: "dfgdfg",textColor:"red"}});
        return result;
    };

    pushNewItem = (textProps: PptxGenJS.TextProps, height: number) => {
        const item: TextObject = {
            TextProps: textProps,
            Height: height,
        };
        if (this.height >= 4) {
            this.result.push(this.arrTextObjs);
            // const overFlowHeight = this.arrTextObjs[this.arrTextObjs.length - 1].Height - this.arrTextObjs[this.arrTextObjs.length - 2].Height;
            const overFlowHeight = height - this.arrTextObjs[this.arrTextObjs.length - 1].Height;
            this.height = overFlowHeight;
            this.arrTextObjs = [];
            item.Height = overFlowHeight;
            this.arrTextObjs.push(item);
            return;
        }
        this.arrTextObjs.push(item);
    };

    formatChildNode = (child: ChildNode) => {
        if (child.nodeName === '#text' && child.textContent === '\n') {
            this.addBreakLine();
        } else if (child.nodeName === 'P') {
            this.formatPChild((child as Node).childNodes);
        } else if (child.nodeName === 'UL' || child.nodeName === 'OL') {
            this.formatListChild((child as HTMLElement).children, child.nodeName, 1);
        } else if (child.nodeName === 'DIV' && (child as HTMLElement).innerHTML?.includes('<code')) {
            this.formatCodeChild((child as HTMLElement).innerText);
        } else if (
            child.nodeName === 'H1' ||
            child.nodeName === 'H2' ||
            child.nodeName === 'H3' ||
            child.nodeName === 'H4' ||
            child.nodeName === 'H5' ||
            child.nodeName === 'H6'
        ) {
            this.addHeaderNode((child as HTMLElement).innerText, child.nodeName);
        } else {
            this.addTextNode((child as HTMLElement).nodeValue as string);
        }
    };

    formatPChild = (childNodes: NodeListOf<ChildNode>, bullet?: string, indentLevel?: number) => {
        let content: string;
        let inline = undefined;
        if (childNodes.length > 1) {
            inline = true;
        }
        for (const child of childNodes) {
            if (child.nodeName === '#text' && child.nodeValue) {
                content = child.nodeValue?.replace(/\n/g, ' ');
                if (content === '' || child.textContent === '\n') {
                    continue;
                } else if (child.nextSibling && child.nextSibling.nodeName === 'CODE') {
                    this.addTextNode(content, bullet, indentLevel, undefined, inline);
                } else {
                    this.addTextNode(content, bullet, indentLevel, true, inline);
                }
            } else if (child.nodeName === 'STRONG' && child.childNodes[0].textContent) {
                this.addStrongNode(child.childNodes[0].textContent, bullet, indentLevel, inline);
            } else if (child.nodeName === 'EM' && child.textContent) {
                this.addEMNode(child.textContent, bullet, indentLevel, inline);
            } else if (child.nodeName === 'CODE' && child.textContent) {
                this.addCodeNode(child.textContent, true);
            }
        }
    };

    formatListElement = (childNode: ChildNode, listType?: string, indentLevel?: number) => {
        if (childNode.nodeName === 'STRONG' && childNode.childNodes[0].textContent) {
            this.addStrongNode(childNode.childNodes[0].textContent, listType, indentLevel);
        } else if (childNode.nodeName === 'EM' && childNode.textContent) {
            this.addEMNode(childNode.textContent, listType, indentLevel);
        } else if (childNode.nodeName === '#text' && childNode.textContent) {
            if (childNode.textContent === '\n') {
                return false;
            } else if (childNode.nextSibling && childNode.nextSibling.nodeName === 'BR') {
                this.addTextNode(childNode.textContent, listType, indentLevel, true, undefined);
            }
            this.addTextNode(childNode.textContent, listType, indentLevel, undefined, undefined);
        } else if (childNode.nodeName === 'P') {
            this.formatPChild(childNode.childNodes, listType, indentLevel);
        } else if (childNode.nodeName === 'UL' || childNode.nodeName === 'OL') {
            if (indentLevel) {
                const incrementedIndentLevel = indentLevel + 1;
                this.formatListChild((childNode as HTMLElement).children, childNode.nodeName, incrementedIndentLevel);
                return false;
            }
        } else if (childNode.nodeName === 'CODE' && childNode.textContent) {
            this.addCodeNode(childNode.textContent, true, listType, indentLevel);
        }
        return true;
    };

    formatListChild = (childNodes: HTMLCollection, listType: string, indentLevel: number) => {
        if (indentLevel === 1) {
            this.addBreakLine();
        } else {
            this.addBreakLine(true); //don't add to height
        }
        for (const child of childNodes) {
            // [li,li,li,li,li]
            const listElementChildNodes = child.childNodes;
            let flag = false; //start the bullet chain
            for (const currentNode of listElementChildNodes) {
                //[#text, p, #text]
                // const currentNode = listElementChildNodes[i];
                if (!flag || currentNode.nodeName === 'UL' || currentNode.nodeName === 'OL') {
                    flag = this.formatListElement(currentNode, listType, indentLevel);
                } else {
                    this.formatListElement(currentNode);
                }
            }
            const ulOrOlElement = Array.from(listElementChildNodes).find(
                (node) => node.nodeName === 'UL' || node.nodeName === 'OL',
            );
            if (!ulOrOlElement) {
                this.addBreakLine(true);
            }
        }
    };

    formatCodeChild = (innerText: string) => {
        const segments: string[] = innerText.split('\n');
        for (const segment of segments) {
            this.addCodeNode(segment, false);
            this.addBreakLine(true);
        }
    };

    addTextNode = (content: string, bullet?: string, indentLevel?: number, breakLine?: boolean, inline?: boolean) => {
        let textProps: PptxGenJS.TextProps;
        let numLineBreaks;
        if (bullet === 'UL') {
            let charCode = '2022'; //filled bullet point
            if (indentLevel === 2) {
                charCode = '26AC'; //hollow circle
            } else if (indentLevel === 3) {
                charCode = '25AA'; //black square
            }
            textProps = { text: content, options: { bullet: { characterCode: charCode }, indentLevel: indentLevel } };
        } else if (bullet === 'OL') {
            textProps = { text: content, options: { bullet: { type: 'number' }, indentLevel: indentLevel } };
        } else if (breakLine) {
            if (inline) {
                content += ' ';
            }
            textProps = { text: content, options: { breakLine: true } };
        } else {
            textProps = { text: content, options: {} };
        }
        if (!indentLevel) {
            indentLevel = 0;
        }
        if (inline && content.length < 100) {
            numLineBreaks = 0;
        } else {
            numLineBreaks = Math.floor(content.length / (110 - indentLevel * 10)) + 1;
        }
        this.height += numLineBreaks * ((1 / 56) * 14 - 47 / 1560);
        const height = this.height;
        this.pushNewItem(textProps, height);
    };

    addStrongNode = (content: string, bullet?: string, indentLevel?: number, inline?: boolean) => {
        let textProps: PptxGenJS.TextProps;

        if (bullet === 'UL') {
            let charCode = '2022'; //filled bullet point
            if (indentLevel === 2) {
                charCode = '26AC'; //hollow circle
            } else if (indentLevel === 3) {
                charCode = '25AA'; //black square
            }
            textProps = {
                text: content,
                options: { bullet: { characterCode: charCode }, indentLevel: indentLevel, bold: true },
            };
        } else if (bullet === 'OL') {
            textProps = {
                text: content,
                options: { bullet: { type: 'number' }, indentLevel: indentLevel, bold: true },
            };
        } else {
            if (inline) {
                content += ' ';
            }
            textProps = { text: content, options: { bold: true } };
        }
        if (!indentLevel) {
            indentLevel = 0;
        }
        const numLineBreaks = Math.floor(content.length / (105 - indentLevel * 10)) + 1;
        this.height += numLineBreaks * ((1 / 56) * 14 - 47 / 1560);
        const height = this.height;
        this.pushNewItem(textProps, height);
    };

    addEMNode = (content: string, bullet?: string, indentLevel?: number, inline?: boolean) => {
        let textProps: PptxGenJS.TextProps;

        if (bullet === 'UL') {
            let charCode = '2022'; //filled bullet point
            if (indentLevel === 2) {
                charCode = '26AC'; //hollow circle
            } else if (indentLevel === 3) {
                charCode = '25AA'; //black square
            }
            textProps = {
                text: content,
                options: { bullet: { characterCode: charCode }, indentLevel: indentLevel, italic: true },
            };
        } else if (bullet === 'OL') {
            textProps = {
                text: content,
                options: { bullet: { type: 'number' }, indentLevel: indentLevel, italic: true },
            };
        } else {
            if (inline) {
                content += ' ';
            }
            textProps = { text: content, options: { italic: true } };
        }
        if (!indentLevel) {
            indentLevel = 0;
        }
        const numLineBreaks = Math.floor(content.length / (110 - indentLevel * 10)) + 1;
        this.height += numLineBreaks * ((1 / 56) * 14 - 47 / 1560);
        const height = this.height;
        this.pushNewItem(textProps, height);
    };

    addCodeNode = (content: string, inline: boolean, bullet?: string, indentLevel?: number) => {
        let textProps: PptxGenJS.TextProps;
        let numLineBreaks;
        if (bullet === 'UL') {
            let charCode = '2022'; //filled bullet point
            if (indentLevel === 2) {
                charCode = '26AC'; //hollow circle
            } else if (indentLevel === 3) {
                charCode = '25AA'; //black square
            }
            textProps = {
                text: content,
                options: { bullet: { characterCode: charCode }, indentLevel: indentLevel, color: '#003F68' },
            };
        } else if (bullet === 'OL') {
            textProps = {
                text: content,
                options: { bullet: { type: 'number' }, indentLevel: indentLevel, color: '#003F68' },
            };
        } else if (content === '') {
            this.height += (1 / 56) * 14 - 47 / 1560;
            return;
        } else {
            textProps = { text: content, options: { color: '#003F68' } };
        }
        //code variables are inline, so they should not take up a linebreak
        if (inline) {
            numLineBreaks = 0;
        } else {
            numLineBreaks = Math.floor(content.length / 110) + 1;
        }
        this.height += numLineBreaks * ((1 / 56) * 14 - 47 / 1560);
        const height = this.height;
        this.pushNewItem(textProps, height);
    };

    addBreakLine = (flag?: boolean) => {
        // if we want to add height to power point
        if (!flag) {
            this.height += (1 / 56) * 14 - 47 / 1560;
        }
        const height = this.height;
        const textProps: PptxGenJS.TextProps = { text: '', options: { breakLine: true } };
        this.pushNewItem(textProps, height);
    };

    addHeaderNode = (content: string, headerType: string) => {
        let numLineBreaks;
        let textProps: PptxGenJS.TextProps;
switch(headerType){
    
    case 'H1': {
        textProps = { text: content, options: { bold: true, fontSize: 28, breakLine: true } };
        numLineBreaks = Math.floor(content.length / 55) + 1;
        this.height += numLineBreaks * ((1 / 56) * 30 - 47 / 1560);
        break;
    } 
    case 'H2': {
        textProps = { text: content, options: { bold: true, fontSize: 21, breakLine: true } };
        numLineBreaks = Math.floor(content.length / 66) + 1;
        this.height += numLineBreaks * ((1 / 56) * 23 - 47 / 1560);
        break;
    } 
    case 'H3': {
        textProps = { text: content, options: { bold: true, fontSize: 16.38, breakLine: true } };
        numLineBreaks = Math.floor(content.length / 85) + 1;
        this.height += numLineBreaks * ((1 / 56) * 19 - 47 / 1560);
        break;
    } 
    case 'H4': {
        textProps = { text: content, options: { bold: true, fontSize: 14, breakLine: true } };
        numLineBreaks = Math.floor(content.length / 110) + 1;
        this.height += numLineBreaks * ((1 / 56) * 16 - 47 / 1560);
        break;
    } 
    case 'H5': {
        textProps = { text: content, options: { bold: true, fontSize: 11.62, breakLine: true } };
        numLineBreaks = Math.floor(content.length / 125) + 1;
        this.height += numLineBreaks * ((1 / 56) * 14 - 47 / 1560);
        break;
    } 
    case 'H6': {
        textProps = { text: content, options: { bold: true, fontSize: 9.38, breakLine: true } };
        numLineBreaks = Math.floor(content.length / 150) + 1;
        this.height += numLineBreaks * ((1 / 56) * 9.38 - 47 / 1560);
        break;
    } 
    default: {
        textProps = { text: content, options: { bold: true, fontSize: 14, breakLine: true } };
        numLineBreaks = Math.floor(content.length / 110) + 1;
        this.height += numLineBreaks * ((1 / 56) * 16 - 47 / 1560);
        break;
    }
}
        const height = this.height;
        this.pushNewItem(textProps, height);
    };

    createSections = () => {
        const result = [];
        const tempDiv = document.createElement('div');
        const childNodes = this.childNodes;
        for (const node of childNodes) {
            //if it is a table
            if (node.nodeName === 'DIV') {
                if (tempDiv.childNodes.length > 0) {
                    result.push(tempDiv.cloneNode(true));
                    tempDiv.innerHTML = '';
                }
                result.push(node.cloneNode(true));
            } else {
                tempDiv.appendChild(node.cloneNode(true));
            }
        }
        if (tempDiv.childNodes.length > 0) {
            result.push(tempDiv.cloneNode(true));
        }
        
        return result;
    };

    addTextSlide = (childNodes: NodeListOf<ChildNode>) => {
        const arrTextObjs: PptxGenJS.TextProps[][] = this.processData(childNodes);
        
        for (const section of arrTextObjs) {
            const options = textSlideOptions;
            const slide = this.pptx.addSlide({ masterName: 'PLACEHOLDER_SLIDE' });
            
            slide.addText(section, options);
        }
    };

    formatTableHeaderRow = (headerRow: NodeListOf<ChildNode>) => {
        const row: PptxGenJS.TableRow = [];
        const tableHeaderCellOptions: PptxGenJS.TableCellProps = {
            fontSize: 14,
            fontFace: 'Segoe UI',
            color: customColors.white,
            fill: {
                color: customColors.protivitiDarkBlue,
            },
            border: { type: 'solid', pt: 0.5, color: 'B8B8B8' },
        };

        for (const cell of headerRow) {
            let data = '';
            if (cell.childNodes.length !== 0 && cell.childNodes[0].textContent) {
                data = cell.childNodes[0].textContent;
            }

            row.push({ text: data, options: tableHeaderCellOptions });
        }
        return row;
    };

    formatTableBodyRows = (result: PptxGenJS.TableRow[], tableBody: NodeListOf<ChildNode>) => {
        let row: PptxGenJS.TableRow = [];
        const tableBodyCellOptions: PptxGenJS.TableCellProps = {
            fontSize: 14,
            fontFace: 'Segoe UI',
            // fill: { color: 'F0F0F0' },
            border: { type: 'solid', pt: 0.5, color: 'B8B8B8' },
        };

        for (const tableBodyRow of tableBody) {
     
            const tableRowChildNodes = tableBodyRow.childNodes;
            for (const cell of tableRowChildNodes) {
                let data = '';
                if (cell.childNodes.length !== 0 && cell.childNodes[0].textContent) {
                    data = cell.childNodes[0].textContent;
                }
                row.push({ text: data, options: tableBodyCellOptions });

                
            }
            result.push(row);
           
           
            row = [];
        }

        return result;
    };
    formatTable = (tableDiv: HTMLElement) => {
       
//let _row:string[]=[];
let _row1:string="";
//let i:number=0;
let rowContent:string="";
let headerContent:string="";
let prevContent:string="";
        const table = tableDiv.querySelector('table');
        if (table) {
           
            if(this.htmlArrayforExport[this.slideNo] && this.htmlArrayforExport[this.slideNo].slideContent)
                {
                    let tempSlideContentLegth=(this.htmlArrayforExport[this.slideNo].slideContent.replace("<font style='font-size:15px;color:black;'>","").replace("<font style='font-size:15px;color:black;font-weight:bold;'>","").replace("</font>","").replace("style='font-size:40px;color:#FFFFFF;background-color:#004068;font-face:Segoe UI;border:0.5px solid #B8B8B8;'","")).length;
                    this.remainingLength=this.lengthOfEachSlide-tempSlideContentLegth;                  
                    prevContent=this.htmlArrayforExport[this.slideNo].slideContent;                  
                   
                }
            const headerRowChildNodes = table.childNodes[0].childNodes[0].childNodes;
            headerContent="<tr style='font-size:40px;color:#FFFFFF;background-color:#004068;font-face:Segoe UI;border:0.5px solid #B8B8B8;'>";
            headerRowChildNodes.forEach(header=>{
            headerContent+="<th>"+header.textContent+"</th>";
});
headerContent +="</tr>";


           
           
            const tableBodyRows = table.childNodes[1].childNodes;
            if(tableBodyRows){
                rowContent="";
            tableBodyRows.forEach(row=>{
                
                rowContent ="<tr style='font-size:40px;color:#FFFFFF;font-face:Segoe UI;border:0.5px solid #B8B8B8'>";
                row.childNodes.forEach(column=>{                  
                   rowContent+="<td>"+column.textContent+"</td>";
                });
                 rowContent+="</tr>";
                 if(this.remainingLength>rowContent.length){
                 
                    _row1+=rowContent;
                 }
                 else{
                   
                   this.htmlArrayforExport[this.slideNo]={slideId:this.slideNo,slideContent:prevContent+"<table width='100%'>"+headerContent+ _row1+"</table>"};
                    this.remainingLength=this.lengthOfEachSlide;
                    this.slideNo++;                   
                    
                    _row1=rowContent;
                  
                    prevContent="";
                   
                 }
                 this.remainingLength=this.remainingLength-rowContent.length;                
            });
        }
        
         
           if(_row1!=""){
           this.htmlArrayforExport[this.slideNo]={slideId:this.slideNo,slideContent:prevContent+"<table width='100%'>"+headerContent+ _row1+"</table>"};
           }
           
           
            prevContent="";
           
        }
       
       
    };
    
    addTableSlide = (tableHeaderText: PptxGenJS.TextProps[], tableDiv: HTMLElement) => {
         this.formatTable(tableDiv);
console.log(tableHeaderText);
       
    };

    handleTablePPTX = (sections: Node[]) => {
        let prevSection: PptxGenJS.TextProps[] = [{ text: '', options: {} }];

        for (let i = 0; i < sections.length; i++) {
            const currentSection = sections[i] as HTMLElement;
            if (currentSection.innerHTML?.includes('<table')) {
                this.addTableSlide(prevSection, currentSection);
                
            } else if (i === sections.length - 1) {
                this.arrTextObjs = [];
                this.result = [];
                this.addTextSlide(currentSection.childNodes);
                
            } else {
                const arrTextObjs: PptxGenJS.TextProps[][] = this.processData(currentSection.childNodes);
                
                prevSection = arrTextObjs[0];
            }
        }
    };

    createPPTX = (fileName: string) => {
        console.log(fileName);
        this.createTemplate();
        const tableElement = this.HTMLdata.querySelector('table');
        if (tableElement) {
            const sections = this.createSections();
           
            this.handleTablePPTX(sections);
        } else {
            const childNodes = this.childNodes;
            this.addTextSlide(childNodes);
        }       

       
        return this.htmlArrayforExport;
      

    };
}

export default ExportUtilsPPTX;
