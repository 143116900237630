import { Button, makeStyles, mergeClasses, shorthands } from '@fluentui/react-components';
import { useNavigate } from 'react-router-dom';

const useClasses = makeStyles({
    button: {
        ...shorthands.border('1px', 'solid', 'transparent'),
        backgroundColor: 'transparent',
        fontWeight: 500,
        ...shorthands.padding('10px', '20px'),
        color: 'white',
        ...shorthands.transition('all', '0.3s', 'ease'),
        '&:hover': {
            ...shorthands.border('1px solid white'),
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
            boxShadow: '0 4px 8px rgba(0, 63, 104, 0.2)',
        },
        '&:active': {
            backgroundColor: 'rgba(255, 255, 255, 0.2)',
            boxShadow: '0 4px 8px rgba(0, 63, 104, 0.3)',
        },
        '&:focus': {
            ...shorthands.outline('none'),
            boxShadow: '0 0 0 2px rgba(0, 63, 104, 0.5)',
        },
    },
    root: {
        justifyContent: 'start',
    },
    small: {
        minWidth: '40px',
        minHeight: '40px',
        width: '40px',
        height: '40px',
        borderRadius: '50%',
        marginLeft:'2px'
    }
});

interface ExploreGPTButtonProps {
    width: number;
}

const ExploreGPTButton: React.FC<ExploreGPTButtonProps> = ({ width }) => {
    const classes = useClasses();
   // const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const onOpenExploreGPTPage = () => {
       // dispatch(setSelectedTab('exploreGPTsTab'));
        navigate('/explore-gpts');
        // dispatch(showRegisterPage(false));
        // dispatch(showUserManagementPage(false));
        // dispatch(showManageGPTsPage(false));
        // dispatch(showEditGPTPage(false));
    };

    return (
        <Button
            data-testid="exploreGPTButton"
            className={width > 64 ? mergeClasses(classes.root,classes.button) : mergeClasses(classes.small, classes.button)}
            style={ width > 64 ? {
                width:  `${width - 30}px`,
                padding: '10px 10px 10px 5px',
                marginLeft: '15px'
                } : {} }
            onClick={() => onOpenExploreGPTPage()}
        >
            <div style={{ display: 'flex', alignItems: 'center' , color: 'white' }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                    <path
                        fill="currentColor"
                        fillRule="evenodd"
                        d="M6.75 4.5a2.25 2.25 0 1 0 0 4.5 2.25 2.25 0 0 0 0-4.5M2.5 6.75a4.25 4.25 0 1 1 8.5 0 4.25 4.25 0 0 1-8.5 0M17.25 4.5a2.25 2.25 0 1 0 0 4.5 2.25 2.25 0 0 0 0-4.5M13 6.75a4.25 4.25 0 1 1 8.5 0 4.25 4.25 0 0 1-8.5 0M6.75 15a2.25 2.25 0 1 0 0 4.5 2.25 2.25 0 0 0 0-4.5M2.5 17.25a4.25 4.25 0 1 1 8.5 0 4.25 4.25 0 0 1-8.5 0M17.25 15a2.25 2.25 0 1 0 0 4.5 2.25 2.25 0 0 0 0-4.5M13 17.25a4.25 4.25 0 1 1 8.5 0 4.25 4.25 0 0 1-8.5 0"
                        clipRule="evenodd"
                    ></path>
                </svg>
                <span style={width > 64 ? { marginLeft: '10px' } : {display: 'none'}}> Explore GPTs </span>
            </div>
        </Button>
    );
};

export default ExploreGPTButton;
