import * as React from 'react';
import { useId, Button, Label, Slider } from '@fluentui/react-components';
import type { SliderProps } from '@fluentui/react-components';
import { IThreadRunConfig } from '../../libs/models/GPT';
import { useAppDispatch, useAppSelector } from '../../redux/app/hooks';
import { RootState } from '../../redux/app/store';
import { updateConversationWithThreadRunConfig } from '../../redux/features/conversations/conversationsSlice';
import { toast } from 'react-toastify';
import { useChat } from '../../libs/hooks';

export const ThreadRunConfig = () => {
    const id = useId();
    const dispatch = useAppDispatch();
    const { conversations, selectedId } = useAppSelector((state: RootState) => state.conversations);
    const selectedThreadConfig = conversations[selectedId]?.threadRunConfig;

    const [temperatureValue, setTemperatureValue] = React.useState(selectedThreadConfig?.temperature || 1);
    const onTemperatureChange: SliderProps['onChange'] = (_, data) => setTemperatureValue(data.value);

    const [maxPromptTokensValue, setMaxPromptTokensValue] = React.useState(
        selectedThreadConfig?.maxPromptTokens || 20000,
    );
    const onMaxPromptTokensChange: SliderProps['onChange'] = (_, data) => setMaxPromptTokensValue(data.value);

    const [maxCompletionTokensValue, setMaxCompletetionTokensValue] = React.useState(
        selectedThreadConfig?.maxCompletionTokens || 1000,
    );
    const onMaxCompletionTokensChange: SliderProps['onChange'] = (_, data) => setMaxCompletetionTokensValue(data.value);

    const [isMenuOpen, setIsMenuOpen] = React.useState(true);
    const chat = useChat();

    const closeMenu = () => setIsMenuOpen(false);

    const saveThreadRunConfig = async () => {
        const threadRunConfig: IThreadRunConfig = {
            temperature: temperatureValue,
            maxPromptTokens: maxPromptTokensValue,
            maxCompletionTokens: maxCompletionTokensValue,
        };

        dispatch(updateConversationWithThreadRunConfig({ chatId: selectedId, threadRunConfig }));
        await chat.editChat(
            conversations[selectedId].id,
            conversations[selectedId].title,
            conversations[selectedId].chatType ? true : false,
            conversations[selectedId].systemDescription,
            conversations[selectedId].memoryBalance,
            conversations[selectedId].gptEndpoint,
            threadRunConfig,
        );

        closeMenu();
        toast.success('Thread run configurations will now be applied for the next prompt!');
    };

    const resetSlider = () => {
        setTemperatureValue(1);
        setMaxPromptTokensValue(16000);
        setMaxCompletetionTokensValue(500);
        closeMenu();
        toast.success('Thread run configurations reset!');
    };

    if (!isMenuOpen) return null;

    return (
        <div style={{ display: 'flex', flexDirection: 'column', width: '300px' }}>
            <Label htmlFor={`${id}-1`}>Temperature: {temperatureValue}</Label>
            <Slider
                aria-valuetext={`Value is ${temperatureValue}`}
                value={temperatureValue}
                min={0}
                max={2}
                step={0.1}
                onChange={onTemperatureChange}
                id={`${id}-1`}
            />

            <Label htmlFor={`${id}-2`}>Max Prompt Tokens: {maxPromptTokensValue}</Label>
            <Slider
                aria-valuetext={`Value is ${maxPromptTokensValue}`}
                value={maxPromptTokensValue}
                min={2000}
                max={30000}
                step={1000}
                onChange={onMaxPromptTokensChange}
                id={`${id}-2`}
            />

            <Label htmlFor={`${id}-3`}>Max Completion Tokens: {maxCompletionTokensValue}</Label>
            <Slider
                aria-valuetext={`Value is ${maxCompletionTokensValue}`}
                value={maxCompletionTokensValue}
                min={500}
                max={2000}
                step={100}
                onChange={onMaxCompletionTokensChange}
                id={`${id}-3`}
            />

            <Button onClick={saveThreadRunConfig}>Save</Button>
            <Button onClick={resetSlider}>Reset</Button>
        </div>
    );
};