import {
    Button,
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
    Divider,
    Tooltip,
    makeStyles,
    shorthands,
} from '@fluentui/react-components';
import { Info16Regular } from '@fluentui/react-icons';
import React from 'react';
import { BotResponsePrompt } from '../../../libs/models/BotResponsePrompt';
import { IChatMessage } from '../../../libs/models/ChatMessage';
import { customColors, customFonts, ScrollBarStyles } from '../../../styles';
import { formatParagraphTextContent } from '../../utils/TextUtils';
import { RootState } from '../../../redux/app/store';
import { useAppSelector } from '../../../redux/app/hooks';

const useClasses = makeStyles({
    infoButton: {
        ...shorthands.padding(0),
        ...shorthands.margin(0),
        minWidth: 'auto',
        marginLeft: 'auto', // align to right
    },
    title: {
        fontFamily: customFonts.TitleFont,
        fontWeight: '600',
        fontSize: '28px',
    },
    text: {
        width: '100%',
        overflowWrap: 'break-word',
        fontFamily: customFonts.Lato,
    },
    surface: {
        ...shorthands.borderRadius('0'),
        height: 'fit-content',
        ...ScrollBarStyles,
    },
    content: {
        fontFamily: customFonts.TitleFont,
        fontWeight: '500',
        fontSize: '20px',
    },
    paragraphs: {
        fontFamily: customFonts.Lato,
        fontSize: '15px',
    },
    innerContent: {
        fontFamily: customFonts.Lato,
        marginTop: '15px',
    },
    unformattedTitle: {
        fontFamily: customFonts.Lato,
        fontSize: '15px',
        fontWeight: '700',
    },
    item: {
        fontFamily: customFonts.Lato,
        fontSize: '12px',
    },
    tablist: {},
    tab: {
        '& > span': {
            fontFamily: customFonts.TitleFont,
            fontSize: '17px',
            fontWeight: '500',
        },
        '&::after': {
            backgroundColor: customColors.protivitiOrange,
            height: '5px',
            ...shorthands.borderRadius('0'),
        },
        '&::before': {
            backgroundColor: customColors.protivitiOrange,
            height: '5px',
            ...shorthands.borderRadius('0'),
        },
        '&::selection': {
            backgroundColor: customColors.protivitiOrange,
            height: '5px',
            ...shorthands.borderRadius('0'),
        },
    },
    footer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        minWidth: '175px',
        fontFamily: customFonts.Lato,
        fontSize: '12px',
    },
    button: {
        backgroundColor: customColors.white,
        fontFamily: customFonts.Lato,
        fontSize: '12px',
        ...shorthands.borderRadius('0'),
        ...shorthands.border('1.5px', 'solid', customColors.protivitiOrange),
        '&:hover': {
            backgroundColor: customColors.summarizeButtonDarker,
            color: customColors.white,
        },
    },
});

interface IPromptDialogProps {
    message: IChatMessage;
}

export const PromptDialog: React.FC<IPromptDialogProps> = ({ message }) => {
    const classes = useClasses();
    const { conversations, selectedId } = useAppSelector((state: RootState) => state.conversations);

    const removeRedundant = (content: string) => {
        let value = content;
        value = /^User intent:/.test(value) ? value.replace('User intent:', '') : value;
        value = /^\[Web Information\] :/.test(value) ? value.replace('[Web Information] :', '') : value;
        return value;
    };

    const formattedResponse = (jsonPrompt: any) => {
        const systemPersona = jsonPrompt.systemPersona;
        const userIntent = jsonPrompt.userIntent;
        const chatMemories = jsonPrompt.chatMemories || 'No chat memories.'; // Using a fallback if empty.
        const chatHistory = jsonPrompt.chatHistory;

        const webInformationContent = jsonPrompt.metaPromptTemplate
            .filter((item: { Role: { Label: string }; Content: string | string[] }) => item.Role.Label === 'tool')
            .map((item: { Content: any }) => item.Content);

        let orderedData = [
            { label: 'System Persona', content: systemPersona },
            { label: 'User Intent', content: userIntent },
            { label: 'Chat Memories', content: chatMemories },
            ...webInformationContent.map((content: any) => ({ label: 'Tool', content })),
            { label: 'Chat History', content: chatHistory },
            { label: 'Message ID', content: message.id },
        ];

        return (
            <div>
                {orderedData.map((item, index) => (
                    <div key={`context-message-${index}`} className={classes.item}>
                        <p className={classes.unformattedTitle}>{item.label}</p>
                        <p>
                            {removeRedundant(item.content)
                                .split('\n')
                                .map((i: string) => (
                                    <p key={index}>{i}</p>
                                ))}{' '}
                        </p>
                        <Divider />
                    </div>
                ))}
            </div>
        );
    };

    let prompt: JSX.Element | JSX.Element[];
    try {
        let jsonPrompt = JSON.parse(message.prompt ?? '{}');
        if ((jsonPrompt as BotResponsePrompt)?.metaPromptTemplate.length > 0) {
            prompt = formattedResponse(jsonPrompt);
        } else {
            prompt = (
                <div>
                    <p>No context available.</p>
                </div>
            );
        }
    } catch (e) {
        prompt = message.prompt ? (
            formatParagraphTextContent(message.prompt)
        ) : (
            <div>
                {Object.entries(message?.tokenUsage ?? {}).map(([key, value]) => (
                    <p key={key}>
                        {(key === 'promptTokens' ? 'Input/' : key === 'completionTokens' ? 'Output/' : '') +
                            key
                                .replace(/([A-Z])/g, ' $1')
                                .replace(/^./, (str) => str.toUpperCase())
                                .trim()}
                        : <span style={{ textDecoration: 'underline' }}>{value}</span>
                    </p>
                ))}
            </div>
        );
    }

    return (
        <Dialog>
            <DialogTrigger disableButtonEnhancement>
                <Tooltip content={'Show prompt'} relationship="label">
                    <Button className={classes.infoButton} icon={<Info16Regular />} appearance="transparent" />
                </Tooltip>
            </DialogTrigger>
            <DialogSurface className={classes.surface}>
                {conversations[selectedId].customGPTId !== process.env.REACT_APP_DEFAULT_PROGPT ? (
                    <DialogBody>
                        <DialogTitle className={classes.title}>Token Usage</DialogTitle>
                        <DialogContent className={classes.content}>
                            <div
                                className={
                                    message.prompt && typeof prompt !== 'string' ? classes.innerContent : undefined
                                }
                            >
                                {prompt}
                            </div>
                        </DialogContent>
                        <DialogActions position="start" className={classes.footer}>
                            <DialogTrigger disableButtonEnhancement>
                                <Button className={classes.button} appearance="secondary">
                                    Close
                                </Button>
                            </DialogTrigger>
                        </DialogActions>
                    </DialogBody>
                ) : (
                    <DialogBody>
                        <DialogTitle className={classes.title}>Prompt</DialogTitle>
                        <DialogContent className={classes.content}>
                            <div
                                className={
                                    message.prompt && typeof prompt !== 'string' ? classes.innerContent : undefined
                                }
                            >
                                {prompt}
                            </div>
                        </DialogContent>
                        <DialogActions position="start" className={classes.footer}>
                            <DialogTrigger disableButtonEnhancement>
                                <Button className={classes.button} appearance="secondary">
                                    Close
                                </Button>
                            </DialogTrigger>
                        </DialogActions>
                    </DialogBody>
                )}
            </DialogSurface>
        </Dialog>
    );
};
