import { Button } from '@fluentui/react-button';
import { makeStyles, mergeClasses, shorthands } from '@fluentui/react-components';
import {
    Dialog,
    DialogBody,
    //DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger
} from '@fluentui/react-dialog';
import { customColors, customFonts } from '../../../../styles';

const useClasses = makeStyles({
    root: {
        width: '450px',
        ...shorthands.borderRadius('0'),
    },
    delete: {
        backgroundColor: customColors.protivitiOrange,
        "&:hover": {
            backgroundColor: customColors.summarizeButtonDarker,
            color: customColors.white
        }
    },
    button: {
        fontFamily: customFonts.Lato,
        fontSize: '12px',
        ...shorthands.borderRadius('0'),
        ...shorthands.border('1.5px', 'solid', customColors.protivitiOrange),

    },
    title: {
        fontFamily: customFonts.TitleFont,
        fontWeight: '500',
        fontSize: '24px',
        paddingBottom: '10%'
    },
    saveButton: {
        ...shorthands.borderRadius('0'),
        backgroundColor: customColors.white,
        width: 'max-content',
        fontWeight: '700',
        fontFamily: customFonts.Lato,
        fontSize: '13.5px',
        ...shorthands.border('1px', 'solid', customColors.protivitiOrange),

        '&: hover > button, &: hover': {
            backgroundColor: customColors.protivitiOrange
        }
    }
});

interface MetaPromptDialogProps {
    save: boolean;
    onSaveButtonClick: (savePrompt: boolean) => void;
}

export const MetaPromptDialog: React.FC<MetaPromptDialogProps>= ({ save, onSaveButtonClick }) => {
    const classes = useClasses();


    return (
        <Dialog modalType="alert">
            <DialogTrigger>
                <Button disabled={!save} className={classes.saveButton}>
                    Save
                </Button>
            </DialogTrigger>
            <DialogSurface className={classes.root}>
                <DialogBody>
                    <DialogTitle className={classes.title}>Does the Meta Prompt reflect the correct persona?</DialogTitle>
                        <DialogTrigger action="close" disableButtonEnhancement>
                            <Button
                                style={{ transition: 'all 0.3s ease' }}
                                className={mergeClasses(classes.delete, classes.button)}
                                onClick={() => {
                                    onSaveButtonClick(true)
                                }}>
                                Yes
                            </Button>
                        </DialogTrigger>
                    <DialogTrigger action="close" disableButtonEnhancement>
                        <Button
                        style={{ transition: 'all 0.3s ease' }}
                        className={mergeClasses(classes.delete, classes.button)}
                        onClick={() => {
                            onSaveButtonClick(false);
                        }}>
                        No
                        </Button>
                    </DialogTrigger>
                </DialogBody>
            </DialogSurface>
        </Dialog>
        
    
    );
};
