import React from 'react';
import { makeStyles } from '@fluentui/react-components';
import { ScrollBarStyles } from '../../../../styles';
const useClasses = makeStyles({
    codeArea: {
        position: 'relative',
        ...ScrollBarStyles,
    },
});

export interface OutputCodeBlockProps {
    className?: string;
    children?: React.ReactNode;

}

export const OutputCodeBlock: React.FC<OutputCodeBlockProps> = ({ ...props }) => {

    const classes = useClasses();

    return <code className={classes.codeArea} {...props} />;
};
