import { ComponentType, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserManagementService } from '../libs/services/UserManagementService';
import { useMsal } from '@azure/msal-react';
import { AuthHelper } from '../libs/auth/AuthHelper';
import { useAppSelector } from '../redux/app/hooks';
import { RootState } from '../redux/app/store';

const withRoleProtection = (WrappedComponent : ComponentType<any>, allowedRoles: string[]) => {
    return (props: any) => {
        const [userRole, setUserRole] = useState('');
        const { instance, inProgress } = useMsal();
        const navigate = useNavigate();
        const { activeUserInfo } = useAppSelector((state: RootState) => state.app);
        const userManagementService = new UserManagementService(process.env.REACT_APP_BACKEND_URI as string);

        useEffect(() => {
            const fetchUserRole = async () => {
                const accessToken = await AuthHelper.getSKaaSAccessToken(instance, inProgress);
                const currentUser = await userManagementService.getUserRoleByEmail(
                    accessToken,
                );
                const role = currentUser?.role?.toString() || '';
                setUserRole(role);
                if (!allowedRoles.includes(role)) {
                    navigate('/'); // Redirect to a "Not Authorized" page or any other page
                }
            };

            if (activeUserInfo?.email) {
                fetchUserRole();
            }
        }, [activeUserInfo, instance, inProgress, navigate]);

        return allowedRoles.includes(userRole) ? <WrappedComponent {...props} /> : null;
    };
};

export default withRoleProtection;