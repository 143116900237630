import { IRating } from "../models/Rating";
import { BaseService } from "./BaseService";

export class RatingService extends BaseService {
 public getRatingAsync = async (
  gptId: string,
  accessToken: string,
): Promise<IRating> => {
return await this.getResponseAsync<IRating>(
  {
    commandPath: `rating/${gptId}`,
    method: 'GET',
  },
  accessToken,
);
}

public updateRatingAsync = async (
  gptId: string,
  data: IRating,
  accessToken: string,
): Promise<IRating> => {
  return await this.getResponseAsync<IRating>(
    {
      commandPath: `rating/${gptId}`,
      method: 'PUT',
      body: data,
    },
    accessToken,
  );
}


  public submitRatingAsync = async (
  data: IRating,
  accessToken: string,
): Promise<IRating> => {
  return await this.getResponseAsync<IRating>(
    {
      commandPath: `rating/create`,
      method: 'POST',
      body: data,
    },
    accessToken,
  );
};
}