import { Table, Button, Modal, Form, Pagination } from 'react-bootstrap';
import { IUser } from '../../libs/models/User';
import { useUserManagement } from '../../libs/hooks/useUserManagement';
import { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import {
    tokens
} from '@fluentui/react-components';
import './UserManagement.css';

export const UserManagement : React.FC = () => {
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const [searchTerm,setSearchTerm] = useState('');
    const { users, totalRecords, updateUser } = useUserManagement(currentPage, itemsPerPage, searchTerm);
    const [showModal, setShowModal] = useState(false);
    const [currentUser, setCurrentUser] = useState<IUser | null>(null);
    const [formData, setFormData] = useState<IUser | null>(null);

    const [searchTerms, setSearchTerms] = useState({
        name: '',
        //email: '',
        login: '',
        role: ''
    });

    const roleMapping: { [key: string]: string } = {
        '0': 'Administrator',
        '1': 'GPTOwner',
        '2': 'User',
    };

    // Calculate the number of pages
    const numPages = Math.ceil(totalRecords / itemsPerPage);

    // Function to handle page change
    const handlePageChange = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };

    const openModal = (user: IUser) => {
        setCurrentUser(user);
        setFormData(user);
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    const handleUpdateUser = () => {
        if (currentUser && formData) {
            const updatedFormData = {
                ...formData,
                role: Number(formData.role),
            };
            updateUser(currentUser.id, updatedFormData)
                .then(() => {
                    toast.success('User updated successfully');
                    setShowModal(false); // Close the modal
                })
                .catch((error) => {
                    console.error('Failed to update user', error);
                    toast.error('Failed to update user');
                });
        }
    };

    const handleInputChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>,
    ) => {
        const updatedFormData = {
            ...formData,
            [event.target.name]: event.target.value,
        };

        if (!updatedFormData.id) {
            console.error('User ID is not defined');
        } else {
            setFormData(updatedFormData as IUser);
        }
    };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>, field: string) => {
        setSearchTerms({
            ...searchTerms,
            [field]: event.target.value,
        });
        setSearchTerm(event.target.value);
    };

    const filteredUsers = Array.isArray(users)
        ? users.filter(
              (user) =>
                  user.name.toLowerCase().includes(searchTerms.name.toLowerCase()) &&
                 // user.emailAddress.toLowerCase().includes(searchTerms.email.toLowerCase()) &&
                  user.loginAddress.toLowerCase().includes(searchTerms.login.toLowerCase()) &&
                  roleMapping[user.role].toLowerCase().includes(searchTerms.role.toLowerCase())
          )
        : [];
    return (
        <div style={{ display: 'flex', flexDirection: 'column', height: '95vh' }}>
            <h1
                style={{
                    marginBottom: '20px',
                    marginTop: '20px',
                    fontSize: '3em',
                    fontWeight: 'bold',
                    display: 'flex',
                    justifyContent: 'center',
                    color: tokens.colorBrandBackgroundHover,
                }}
            >
                User Management
            </h1>
            <div className="scrollable-content" style={{flex: '1 1 auto', overflowY: 'auto'}}>
                <Table
                    className="table table-striped"
                    style={{ width: '100%', tableLayout: 'fixed', marginLeft: '20px' }}
                >
                    <thead>
                        <tr style={{ backgroundColor: '#f8f9fa', color: '#212529' }}>
                            <th>Name</th>
                            {/* <th>Email Address</th> */}
                            <th>Login Address</th>
                            <th>Role</th>
                            <th>Actions</th>
                        </tr>
                        <tr>
                            <th>
                                <div style={{ position: 'relative' }}>
                                    <input
                                        type="text"
                                        onChange={(event) => handleSearchChange(event, 'name')}
                                        style={{
                                            padding: '5px',
                                            borderRadius: '5px',
                                            border: '1px solid #ccc',
                                            paddingLeft: '25px',
                                        }}
                                    />
                                    <span
                                        style={{
                                            position: 'absolute',
                                            left: '10px',
                                            top: '50%',
                                            transform: 'translateY(-50%)',
                                        }}
                                    >
                                        🔍
                                    </span>
                                </div>
                            </th>
                            {/* <th>
                                <div style={{ position: 'relative' }}>
                                    <input
                                        type="text"
                                        onChange={(event) => handleSearchChange(event, 'email')}
                                        style={{
                                            padding: '5px',
                                            borderRadius: '5px',
                                            border: '1px solid #ccc',
                                            paddingLeft: '25px',
                                        }}
                                    />
                                    <span
                                        style={{
                                            position: 'absolute',
                                            left: '10px',
                                            top: '50%',
                                            transform: 'translateY(-50%)',
                                        }}
                                    >
                                        🔍
                                    </span>
                                </div>
                            </th> */}
                            <th>
                                <div style={{ position: 'relative' }}>
                                    <input
                                        type="text"
                                        onChange={(event) => handleSearchChange(event, 'login')}
                                        style={{
                                            padding: '5px',
                                            borderRadius: '5px',
                                            border: '1px solid #ccc',
                                            paddingLeft: '25px',
                                        }}
                                    />
                                    <span
                                        style={{
                                            position: 'absolute',
                                            left: '10px',
                                            top: '50%',
                                            transform: 'translateY(-50%)',
                                        }}
                                    >
                                        🔍
                                    </span>
                                </div>
                            </th>
                            <th>
                                <div style={{ position: 'relative' }}>
                                    <input
                                        type="text"
                                        onChange={(event) => handleSearchChange(event, 'role')}
                                        style={{
                                            padding: '5px',
                                            borderRadius: '5px',
                                            border: '1px solid #ccc',
                                            paddingLeft: '25px',
                                        }}
                                    />
                                    <span
                                        style={{
                                            position: 'absolute',
                                            left: '10px',
                                            top: '50%',
                                            transform: 'translateY(-50%)',
                                        }}
                                    >
                                        🔍
                                    </span>
                                </div>
                            </th>
                            {/* <th><input type="text" placeholder="Search..." onChange={(event) => handleSearchChange(event, 'name')} /></th> */}
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredUsers.length > 0 ? (
                            filteredUsers.map((user: IUser) => (
                                <tr  key={user.id}>
                                    <td>{user.name}</td>
                                    {/* <td>{user.emailAddress}</td> */}
                                    <td>{user.loginAddress}</td>
                                    <td>{roleMapping[user.role]}</td>
                                    <td>
                                        <button
                                            className="btn btn-outline-primary"
                                            style={{ padding: '10px', fontSize: '16px', borderRadius: '5px' }}
                                            onClick={() => openModal(user)}
                                        >
                                            Edit
                                        </button>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan={5} style={{ textAlign: 'center' }}>
                                    No Data
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </div>
            <div style={{ flexShrink: 0, alignSelf: 'center'}}>
                <Pagination>
                    <Pagination.First onClick={() => handlePageChange(1)} disabled={currentPage === 1} />
                    <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
                    {[...Array(numPages).keys()].map((page) => (
                        <Pagination.Item
                            key={page + 1}
                            active={page + 1 === currentPage}
                            onClick={() => handlePageChange(page + 1)}
                        >
                            {page + 1}
                        </Pagination.Item>
                    ))}
                    <Pagination.Next
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={currentPage === numPages}
                    />
                    <Pagination.Last onClick={() => handlePageChange(numPages)} disabled={currentPage === numPages} />
                </Pagination>
            </div>
            <Modal show={showModal} onHide={closeModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit User</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group>
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                name="name"
                                type="text"
                                defaultValue={currentUser?.name}
                                onChange={handleInputChange}
                            />
                        </Form.Group>
                        {/* <Form.Group>
                            <Form.Label>Email Address</Form.Label>
                            <Form.Control
                                name="emailAddress"
                                type="email"
                                defaultValue={currentUser?.emailAddress}
                                onChange={handleInputChange}
                            />
                        </Form.Group> */}
                        <Form.Group>
                            <Form.Label>Login Address</Form.Label>
                            <Form.Control
                                name="loginAddress"
                                type="text"
                                defaultValue={currentUser?.loginAddress}
                                onChange={handleInputChange}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Role</Form.Label>
                            <Form.Control
                                name="role"
                                as="select"
                                defaultValue={currentUser?.role}
                                onChange={handleInputChange}
                            >
                                {Object.entries(roleMapping).map(([value, label]) => (
                                    <option  key={value} value={value}>{label}</option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeModal}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleUpdateUser}>
                        Update
                    </Button>
                </Modal.Footer>
            </Modal>
            <ToastContainer />
        </div>
    );
};

//export default UserManagement;
