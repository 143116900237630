/* eslint-disable padded-blocks */
import React from 'react';
import { Button, Menu, MenuItem, MenuList, MenuPopover, MenuTrigger, Tooltip, Text, Spinner } from '@fluentui/react-components';
import { ArrowDownload16Regular } from '@fluentui/react-icons';
import { makeStyles } from '@fluentui/react-components';
import * as XLSX from 'xlsx';
import PptxGenJS from 'pptxgenjs';
import { asBlob } from 'html-docx-js-typescript';
import WordIcon from '../../../assets/icons8-microsoft-word-2019-48.png';
import ExcelIcon from '../../../assets/icons8-microsoft-excel-2019-48.png';
import PowerPointIcon from '../../../assets/icons8-microsoft-powerpoint-2019-48.png';
import { useChat } from '../../../libs/hooks/useChat';
import { useAppSelector } from '../../../redux/app/hooks';
import { RootState } from '../../../redux/app/store';
import ExportUtilsPPTX from '../../utils/ExportUtilsPPTX';
import { useDispatch } from 'react-redux';
import { setCurrentExportMessageId, setIsExportInProgress } from '../../../redux/features/commonData/CommonDataSlice';



const useClasses = makeStyles({
    buttonContainer: {},
    menuButton: {},
    menuItemContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    menuImage: {
        width: '24px',
        height: '24px',
        paddingRight: '10px',
    },
});


interface ExportButtonProps {
    id: string | undefined;
    chatName: string;
    disableExcel: boolean;
}

const defaultText: PptxGenJS.TextProps = {
    text: 'Default Text',
    options: {
        x: 0.0,
        y: 0.0,
        w: '100%',
        h: 1.5,
        align: 'left',
        fontSize: 14,
    },
};

const Export2Word = async (id: string | undefined, filename?: string) => {
    if (!id) {
        console.error('Invalid element ID');
        return;
    }

    const targetElement = document.getElementById(id);

    if (!targetElement) {
        console.error(`Element with ID '${id}' not found`);
        return;
    }

    const preHTML = `
        <!DOCTYPE html>
        <html lang="en">
        <head>
            <meta charset="UTF-8" />
            <title>Document</title>
            <style>
                body {
                    font-family: "Arial", sans-serif;
                    font-size: 10pt;
                    line-height: 14pt;
                }
                h1 {
                    font-family: "Barlow Condensed Medium", sans-serif;
                    font-weight: normal;
                    color: #004068;
                    font-size: 32pt;
                    margin-top: 20pt;
                    margin-bottom: 20pt;
                }
                h2, h3 {
                    color: #004068;
                }
                p,
                li {
                    margin-top: 0pt;
                    margin-bottom: 6pt;
                }

                code {
                    background-color: #f5f5f5;
                    color: #004068;
                }

                table {
                    width: 100%;
                    border-collapse: collapse;
                    font-size: 9pt;
                }
                th,
                td {
                    line-height: 9pt;
                    border: 1px solid #ffffff;
                    text-align: center;
                    margin: auto 0;
                    padding: 10px;
                }
                th,
                tfoot td {
                    font-size: 10pt;
                    line-height: 10pt;
                    background-color: #004068;
                    color: #ffffff;
                }
                .oddRow {
                    background-color: #E5F5F7;
                }
                .evenRow {
                    background-color: #FFFFFF;
                }
            </style>
        </head>
        <body>`;
    const postHTML = '</body></html>';
    const content = targetElement.innerHTML;

    // Assuming `content` is the innerHTML string you provided
    const parser = new DOMParser();
    const doc = parser.parseFromString(content, 'text/html');

    // Find all tables within the parsed content
    const tables = doc.querySelectorAll('table');

    tables.forEach((table) => {
        // Get all rows in the current table
        const rows = table.querySelectorAll('tr');

        // Iterate through each row
        rows.forEach((row, index) => {
            // Apply .oddRow or .evenRow class based on the row index
            if (index % 2 === 0) {
                row.classList.add('evenRow');
            } else {
                row.classList.add('oddRow');
            }
        });
    });

    // Convert the modified content back to a string if needed
    const modifiedContent = doc.body.innerHTML;

    const html = preHTML + modifiedContent + postHTML;

    try {
        const converted = await asBlob(html, {
            orientation: 'portrait',
            margins: { top: 720, right: 720, bottom: 720, left: 720 },
        });

        const blob = new Blob([converted], {
            type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        });
        const url = window.URL.createObjectURL(blob);

        // Create a temporary link to download the blob
        const downloadLink = document.createElement('a');
        downloadLink.href = url;
        downloadLink.download = filename ? `${filename}.docx` : 'document.docx';

        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);

        window.URL.revokeObjectURL(url);
    } catch (error) {
        console.log('Error');
    }
};

const Export2Excel = (id: string | undefined, filename?: string) => {
    if (!id) {
        console.error('Invalid element ID');
        return;
    }

    const targetElement = document.getElementById(id);

    if (!targetElement) {
        console.error(`Element with ID '${id}' not found`);
        return;
    }

    const tables = targetElement.getElementsByTagName('table');
    const tableArray = Array.from(tables);
    const wb = XLSX.utils.book_new();

    tableArray.forEach((table, index) => {
        const ws = XLSX.utils.table_to_sheet(table);
        const sheetName = `Table_${index + 1}`;
        XLSX.utils.book_append_sheet(wb, ws, sheetName);
    });

    // Specify file name
    filename = filename ? filename + '.xlsx' : 'excel_data.xlsx';

    // Save the workbook to a file
    XLSX.writeFile(wb, filename);
};

const Export2PPTX = async (id: string | undefined, filename: string,chat:any,chatId:string,dispatch:any) => {
    dispatch(setIsExportInProgress(true));
    dispatch(setCurrentExportMessageId(id!=undefined?id:""));
    
   
    if (!id) {
        console.error('Invalid element ID');
        return;
    }

    const targetElement = document.getElementById(id);

    if (!targetElement) {
        console.error(`Element with ID '${id}' not found`);
        return;
    }

    const innerDiv = targetElement.querySelector('div');

    if (innerDiv) {
        const myObject = new ExportUtilsPPTX({ data: innerDiv });
       var slideContent= myObject.createPPTX(filename);
        
      var blobResponse = await chat.exportPPTXDocument(chatId, slideContent,filename);    
      const url = window.URL.createObjectURL(blobResponse);
      const link = document.createElement('a');
      link.href = url;
      link.download = filename+'.pptx';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);    
      dispatch(setIsExportInProgress(false));
    } else {
        const pptx = new PptxGenJS();
        const slide = pptx.addSlide();
        slide.addText([defaultText]);
        void pptx.writeFile({ fileName: 'Error downloading' });
    }
};

export const ExportButton: React.FC<ExportButtonProps> = ({ id, chatName, disableExcel }) => {
    const classes = useClasses();
    const chat = useChat();
    const dispatch = useDispatch();
    const disablePPTxExport:boolean=false;
    const [hover, setHover] = React.useState('');
    const  isExportInProgress  = useAppSelector((state: RootState) => state.commonData.isExportInProgress);
    const  currentExportMessageId  = useAppSelector((state: RootState) => state.commonData.currentExportMessageId);
    const {  selectedId } = useAppSelector((state: RootState) => state.conversations);   
    const handleMouseEnter = (section: string) => {
        setHover(section);
    };

    const handleMouseLeave = () => {
        if (!id) {
            console.error('Invalid element ID');
            return;
        }
    };

    return (
        <div className={classes.buttonContainer}>
             { isExportInProgress  && currentExportMessageId==id && <Spinner size="tiny"  style={{paddingTop:'5px'}} />}
           { (!isExportInProgress || currentExportMessageId!=id) && <Menu>
                <MenuTrigger disableButtonEnhancement>
                    <Tooltip content="Export" relationship="label">
                        <Button
                            icon={<ArrowDownload16Regular />}
                            style={{ border: 'none' }}
                            className={classes.menuButton}
                        ></Button>
                        
                    </Tooltip>
                   
                </MenuTrigger>
                <MenuPopover>
                    <MenuList>
                        <MenuItem
                            onClick={() => {
                                void Export2Word(id, chatName);
                            }}
                        >
                            <div
                                className={classes.menuItemContainer}
                                id="Word"
                                onMouseEnter={() => {
                                    handleMouseEnter('Word');
                                }}
                                onMouseLeave={handleMouseLeave}
                            >
                                <img src={WordIcon} alt="Word Icon" className={classes.menuImage} />
                                <Text style={{ fontWeight: hover === 'Word' ? 600 : 400 }}>Word</Text>
                            </div>
                        </MenuItem>
                        {!disableExcel && (
                            <MenuItem
                                onClick={() => {
                                    Export2Excel(id, chatName);
                                }}
                            >
                                <div
                                    className={classes.menuItemContainer}
                                    id="Excel"
                                    onMouseEnter={() => {
                                        handleMouseEnter('Excel');
                                    }}
                                    onMouseLeave={handleMouseLeave}
                                >
                                    <img src={ExcelIcon} alt="Excel Icon" className={classes.menuImage} />
                                    <Text style={{ fontWeight: hover === 'Excel' ? 600 : 400 }}>Excel</Text>
                                </div>
                            </MenuItem>
                        )}
                      {!disablePPTxExport &&(  <MenuItem
                            onClick={() => {
                                Export2PPTX(id,chatName,chat,selectedId,dispatch);
                            }}
                        >
                            <div
                                className={classes.menuItemContainer}
                                id="PowerPoint"
                                onMouseEnter={() => {
                                    handleMouseEnter('PowerPoint');
                                }}
                                onMouseLeave={handleMouseLeave}
                            >
                                <img src={PowerPointIcon} alt="PowerPoint Icon" className={classes.menuImage} />
                                <Text style={{ fontWeight: hover === 'PowerPoint' ? 600 : 400 }}>Powerpoint</Text>  
                            </div>
                        </MenuItem>
                        )}
                    </MenuList>
                </MenuPopover>
            </Menu>}
        </div>
    );
};
