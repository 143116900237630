import React, { ReactNode, ReactElement } from 'react';
import { makeStyles, shorthands } from '@fluentui/react-components';
import { customTokens, ScrollBarStyles } from '../../../../styles';

const useClasses = makeStyles({
    tableContainer: {
        maxWidth: '100%', // could change to 80% if you don't want tables to take up the full width
        marginTop: '10px',
        marginBottom: '10px',
        overflowX: 'auto',
        boxSizing: 'border-box',

        '& table': {
            // width: '100%',
            tableLayout: 'fixed',
            borderSpacing: '0',
            ...shorthands.borderTop('1px', 'solid', customTokens.colorNeutralBackground6),
            ...shorthands.borderLeft('1px', 'solid', customTokens.colorNeutralBackground6),
            // ...shorthands.overflow('hidden'),
            // borderCollapse: 'collapse',
            // ...shorthands.borderBottom('1px solid #ddd'),
            // ...shorthands.borderRadius('5px'),
            wordBreak: 'normal',
        },
        '& th, & td': {
            ...shorthands.padding('5px'),
            verticalAlign: 'top',
            ...shorthands.borderBottom('1px', 'solid', customTokens.colorNeutralBackground6),
            ...shorthands.borderRight('1px', 'solid', customTokens.colorNeutralBackground6),
            boxSizing: 'border-box',
            minWidth: '10vw',
            width: 'auto',
        },
        '& th': {
            backgroundColor: customTokens.colorNeutralBackground2,
            fontWeight: 500,
            textAlign: 'left',
        },
        '& ul': {
            ...shorthands.margin('0'),
            paddingLeft: '20px',
        },
        ...ScrollBarStyles,
    },
});

// Function to parse through a string array and create React elements from it
const CreateElementsFromStringArray = (strings: string[]): ReactNode[] => {
    const elements: ReactNode[] = [];
    let currentList: ReactNode[] = [];
    let listItemContent: string[] = []; // Using an array for efficient string concatenation
    let inListItem: boolean = false;

    strings.forEach((str) => {
        switch (str) {
            case '<ul>':
                currentList = []; // Start a new list
                break;
            case '</ul>':
                elements.push(<ul>{currentList}</ul>); // Close the list and add it to elements
                currentList = [];
                break;
            case '<li>':
                inListItem = true;
                listItemContent = []; // Start a new array to contain the strings to be put inside the <li></li>
                break;
            case '</li>':
                currentList.push(<li>{listItemContent.join('')}</li>); // join the listItemContent array into one string and add the <li>
                inListItem = false; // close out the list
                break;
            case '<br>':
            case '<br/>':
                elements.push(<br />);
                break;
            default:
                if (inListItem)
                    listItemContent.push(str); // if we're inside <li></li>, add the string to the listItemContent
                else elements.push(str); // if it's just a string, then just push it as such
        }
    });

    return elements;
};

// function to navigate down the table structure
const parseThroughTable = (child: ReactNode): ReactNode => {
    if (React.isValidElement(child) && child.props.children) {
        const newChildren: ReactNode = replaceHTML(child.props.children);
        return React.cloneElement(child, { ...child.props, children: newChildren });
    }
    return child;
};

// function to recursively navigate down / parse through the table structure to get to the bottom level (an array of strings), and then run CreateElementsFromStringArray on that
const replaceHTML = (children: ReactNode): ReactNode[] => {
    if (Array.isArray(children)) {
        const hasStringChildren = children.some((child) => typeof child === 'string');
        return hasStringChildren
            ? CreateElementsFromStringArray(children as string[]) // the <td>'s contain an array of strings
            : children.flatMap(parseThroughTable); // if not on bottom level (and in an array), continue
    } else {
        return [parseThroughTable(children)]; // if not on bottom level, continue
    }
};

interface OutputTableProps {
    children?: ReactNode;
}

export const OutputTable: React.FC<OutputTableProps> = (props) => {
    const classes = useClasses();

    const cleanedChildren: ReactNode = replaceHTML(props.children as ReactElement[]);

    return (
        <div className={classes.tableContainer}>
            <table>{cleanedChildren}</table>
        </div>
    );
};
