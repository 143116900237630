import { Button, Tooltip, makeStyles, shorthands } from '@fluentui/react-components';
import { ArrowRepeatAll16Regular } from '@fluentui/react-icons';
import React from 'react';
import { useChat } from '../../../libs/hooks';
import { GetResponseOptions } from '../../../libs/hooks/useChat';
import { AuthorRoles, ChatMessageType } from '../../../libs/models/ChatMessage';
import { useAppDispatch, useAppSelector } from '../../../redux/app/hooks';
import { RootState } from '../../../redux/app/store';
import { updateBotResponseStatus } from '../../../redux/features/currentmessages/currentmessagesSlice';
import { shallowEqual } from 'react-redux';

const useClasses = makeStyles({
    container: {
        ...shorthands.padding(0, 0, 0, '5px'),
    },
});

interface RegenerateButtonProps {
    setAbortController: React.Dispatch<React.SetStateAction<AbortController>>;
}

export const RegenerateButton: React.FC<RegenerateButtonProps> = ({ setAbortController }) => {
    const dispatch = useAppDispatch();
    const chat = useChat();
    const classes = useClasses();
    const { conversations, selectedId } = useAppSelector((state: RootState) => state.conversations);
    const isResponseGenerating = useAppSelector(
        (state: RootState) => state.currentmessages.generatingMessages[selectedId]?.isResponseGenerating,
        shallowEqual,
    );
    const [disabled, setDisabled] = React.useState(false);

    const handleOnClick = async () => {
        const regeneratePrompt = 'Requesting to regenerate response for prompt: ';
        const userMessage = conversations[selectedId].messages
            .slice()
            .reverse()
            // .sort((a, b) => {
            //         return new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime();
            // }) // reverse sorted based on times, not based on the saved order (more consistent this way)
            .find((message) => message.authorRole === AuthorRoles.User);
        if (userMessage) {
            setDisabled(true);
            const content = regeneratePrompt + userMessage.content;
            console.log(content);
            // dispatch(editConversationInput({ id: selectedId, newInput: '' }));
            dispatch(
                updateBotResponseStatus({
                    chatId: selectedId,
                    status: 'Re-dispatching previous prompt to ProGPT. Please reload the page if app is stuck in this state for more than 20 seconds',
                }),
            );

            const options: GetResponseOptions = {
                messageType: ChatMessageType.Message,
                chatId: selectedId,
                isDocumentChat: conversations[selectedId].chatType,
                value: content,
                regenerate: true,
                gptEndpoint: conversations[selectedId].gptEndpoint,
                threadRunConfig: conversations[selectedId].threadRunConfig ?? { temperature: 1 }
            };
            const controller = new AbortController();
            setAbortController(controller);

            try {
                await chat.getResponse(options, controller.signal);
            } catch (error) {
                console.error('Error in regenerating chat message, ', error);
            }
            setDisabled(false);
        }
    };

    return (
        <div className={classes.container}>
            <Tooltip content={disabled || isResponseGenerating ? 'Please wait!' : 'Regenerate'} relationship="label">
                <Button
                    disabled={disabled || isResponseGenerating}
                    icon={<ArrowRepeatAll16Regular />}
                    style={{ border: 'none' }}
                    onClick={() => {
                        handleOnClick();
                    }}
                ></Button>
            </Tooltip>
        </div>
    );
};
