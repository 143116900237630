import { Field, Textarea, Input, Text } from '@fluentui/react-components';
import * as React from 'react';
import { Control, Controller } from 'react-hook-form';
import { customColors } from '../../styles';
import { useState } from 'react';
import { ReactNode } from 'react';

interface FormFieldProps {
    control: Control<any>;
    name: string;
    label: ReactNode;
    rules?: any;
    defaultValue?: string;
    errors: any;
    multiline?: boolean;
    className?: string;
    placeholder?: string;
    maxChars?: number;
}

export const FormTextField: React.FC<FormFieldProps> = (props) => {
    const [inputValue, setInputValue] = useState(props.defaultValue);

    const handleChange = (e: { target: { value: any } }, fieldOnChange: (arg0: any) => void) => {
        const newValue = e.target.value;
        if (!props.maxChars || newValue.length <= props.maxChars) {
            setInputValue(newValue); // Update state only if within limit
            fieldOnChange(e); // Use the provided field.onChange method
        }
    };

    const getColorForCharacterCount = (currentLength?: number, maxLength?: number) => {
        if (!maxLength || !currentLength) return 'gray';
        const ratio = currentLength / maxLength;
        if (ratio > 0.9) return customColors.warningRed;
        if (ratio > 0.75) return customColors.protivitiOrange;
        return 'gray';
    };

    return (
        <>
            <Field
                label={<Text className={props.className}>{props.label}</Text>}
                validationState={props.errors ? 'error' : 'none'}
                validationMessage={props.errors ? props.errors.message : null}
            >
                <>
                    <Controller
                        name={props.name}
                        control={props.control}
                        defaultValue={props.defaultValue}
                        rules={props.rules}
                        render={({ field }) =>
                            props.multiline ? (
                                <Textarea
                                    {...field}
                                    resize="vertical"
                                    appearance="outline"
                                    placeholder={props.placeholder}
                                    value={inputValue}
                                    style={{ borderRadius: 0, border: `2px solid ${customColors.headerLightGray}` }}
                                    onChange={(e) => handleChange(e, field.onChange)}
                                />
                            ) : (
                                <Input
                                    appearance="outline"
                                    {...field}
                                    value={inputValue}
                                    placeholder={props.placeholder}
                                    style={{ borderRadius: 0, border: `2px solid ${customColors.headerLightGray}` }}
                                    onChange={(e) => handleChange(e, field.onChange)}
                                />
                            )
                        }
                    />
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            alignItems: 'end',
                            marginTop: '8px',
                        }}
                    >
                        {props.maxChars && (
                            <span style={{ color: getColorForCharacterCount(inputValue?.length, props.maxChars) }}>
                                {`${inputValue?.length}/${props.maxChars} characters`}
                            </span>
                        )}
                    </div>
                </>
            </Field>
        </>
    );
};
