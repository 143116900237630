import React from 'react';
import { Button, makeStyles } from '@fluentui/react-components';
import { ArrowExpand24Regular, ArrowMinimize24Regular } from '@fluentui/react-icons';

interface ImageScaleButtonProps {
  iconType: 'maximize' | 'minimize';
  onClick: () => void;
}

const useClasses = makeStyles({
  minimizeIcon: {
    cursor: 'pointer',
    borderRadius: '50%',
    padding: '5px',
    backgroundColor: '#f4f4f4',
  },
  maximizeIcon: {
    cursor: 'pointer',
    borderRadius: '50%',
    padding: '5px',
    backgroundColor: '#f4f4f4',
  },
});


const ImageScaleButton: React.FC<ImageScaleButtonProps> = ({
  iconType,
  onClick,
}) => {
  const Icon = iconType === 'maximize' ? ArrowExpand24Regular : ArrowMinimize24Regular;
  const classes = useClasses();
  const [hover, setHover] = React.useState(false);
  const handleMouseOver = (e: React.MouseEvent) => {
    e.preventDefault();
    setHover(true);
    };

  const handleMouseLeave = (e: React.MouseEvent) => {
    e.preventDefault();
    setHover(false);
    };

  return (
    <Button
        icon={<Icon className={iconType === 'maximize' ? classes.maximizeIcon : classes.minimizeIcon }/>}
        appearance="transparent"
        onClick={onClick}
        onMouseOver={handleMouseOver}
        onMouseLeave={handleMouseLeave}
        style={{
            display: 'inline-block',
            transition: 'transform 0.5s ease',
            borderRadius: '5px',
            transform: hover ? 'scale(1.8)' : 'none',
          }}
    />
  );
};

export default ImageScaleButton;