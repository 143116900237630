import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { removeGeneratingMessage, updateBotResponseStatus } from './currentmessagesSlice';
import { removeMessageFromConversation, updateMessageProperty } from '../conversations/conversationsSlice';

export const abortCleanUp = createAsyncThunk(
    'currentmessages/abortCleanUp',
    async (chatId: string, { dispatch, getState }) => {
        const state = getState() as RootState;
        const generatingMessage = state.currentmessages.generatingMessages[chatId]?.message;

        if (
            generatingMessage &&
            generatingMessage.id &&
            (generatingMessage.content === '' || (generatingMessage.content.length ?? 0) === 0)
        ) {
            dispatch(removeMessageFromConversation({ chatId: chatId, messageId: generatingMessage.id }));
        } else if (generatingMessage?.content && generatingMessage.content.length > 0) {
            dispatch(
                updateMessageProperty({
                    chatId: chatId,
                    messageIdOrIndex: generatingMessage.id,
                    property: 'content',
                    value: generatingMessage.content,
                    citationsArray: generatingMessage.citations,
                    frontLoad: true,
                }),
            );
        }

        dispatch(removeGeneratingMessage({ chatId: chatId }));
        dispatch(updateBotResponseStatus({ chatId: chatId, status: '' }));
    },
);
