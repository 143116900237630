import React from 'react';
import { ToolTipBlock } from './ToolTipBlock';
import { PromptLibrary } from '../../../libs/models/GPT';

interface ToolTipBlockWrapperProps {
    setValue: React.Dispatch<React.SetStateAction<string>>;
    promptLibrary: PromptLibrary[] | undefined;
}

const transformPromptLibrary = (promptLibrary: PromptLibrary[]) => {
    const suggestions = promptLibrary.reduce((acc, libraryItem) => {
        // Handle smallBlock
        libraryItem.smallBlock.forEach(block => {
            if (!acc["Small blocks"]) {
                acc["Small blocks"] = {};
            }
            acc["Small blocks"][block.name] = block.description;
        });
        
        // TODO: Handle largeBlock
        return acc;
    }, {} as Record<string, Record<string, string>>);

    return suggestions;
};

const ToolTipBlockWrapper: React.FC<ToolTipBlockWrapperProps> = ({ setValue, promptLibrary  }) => {
    const suggestions = transformPromptLibrary(promptLibrary ?? []);
    return (
        <ToolTipBlock
            category="Small blocks"
            suggestions={suggestions}
            setValue={setValue}
        />
    );
};

export default ToolTipBlockWrapper;