import React from 'react';
import { ChatWindowBlock } from './ChatWindowBlock';
import { PromptLibrary } from '../../../libs/models/GPT';


interface ChatWindowBlockWrapperProps {
    setValue: React.Dispatch<React.SetStateAction<string>>;
    promptLibrary: PromptLibrary[] | undefined;
    style?: React.CSSProperties;
    accessedFromOverview?: boolean;
}

const transformPromptLibrary = (promptLibrary: PromptLibrary[]) => {
    const suggestions = promptLibrary.reduce(
        (acc, libraryItem) => {
            // Handle smallBlock
            libraryItem.smallBlock.forEach((block) => {
                if (!acc['Small blocks']) {
                    acc['Small blocks'] = {};
                }
                acc['Small blocks'][block.name] = block.description;
            });

            return acc;
        },
        {} as Record<string, Record<string, string>>,
    );

    return suggestions;
};

const ChatWindowBlockWrapper: React.FC<ChatWindowBlockWrapperProps> = ({
    setValue,
    promptLibrary,
    style,
    accessedFromOverview,
}) => {
    const suggestions = transformPromptLibrary(promptLibrary ?? []);
    return (
        <ChatWindowBlock
            category="Small blocks"
            suggestions={suggestions}
            setValue={setValue}
            style={style}
            accessedFromOverview={accessedFromOverview}
        />
    );
};

export default ChatWindowBlockWrapper;