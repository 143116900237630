import { IChatMessage } from "../../libs/models/ChatMessage";

// Function that fetches and parses a JSON file
export const fetchJson = async (filePath: URL): Promise<Response> => {
    try {
        // Make a GET request to the file URL
        const response = await fetch(filePath);

        if (response.ok) {
            return response;
        } else {
            throw new Error(`Failed to fetch ${filePath.toString()}: ${response.status}`);
        }
    } catch (err) {
        throw new Error(`Failed to fetch ${filePath.toString()}`);
    }
};

export function base64ToArrayBuffer(base64: string): ArrayBuffer {
    const binaryString = window.atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
}

export function getAnnotationVariablesFromMessage(message: IChatMessage): {fileName: string, fileId: string} {
    if (message.annotations && message.annotations.length > 0) {
        const annotationPattern = /^(.+?):\s*(.+)$/;
        const match = message.annotations[0].match(annotationPattern);
        if (match) {
            const f = match[1];
            const s = match[2];
            console.log(f, s);
            return { fileName: match[1], fileId: match[2] };
        }else{
            return {fileName: 'file', fileId: message.annotations[0]};
        }
    }
    return {fileName: '', fileId: ''};
}
