import { makeStyles, shorthands } from '@fluentui/react-components';
import React from 'react';
import { useAppSelector } from '../../../redux/app/hooks';
import { RootState } from '../../../redux/app/store';
//import { editConversationInput } from '../../../redux/features/conversations/conversationsSlice';
import { customFonts, customColors } from '../../../styles';
import { setSelectedTab } from '../../../redux/features/conversations/conversationsSlice';
import { shallowEqual, useDispatch } from 'react-redux';
import { appInsights } from '../../../App'; // Import appInsights
import { updateChatInput } from '../../../redux/features/currentmessages/currentmessagesSlice';

const useClasses = makeStyles({
    gridContainer: {
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        '@media (max-width: 1410px)': {
            gridTemplateColumns: 'repeat(2, 1fr)',
        },
        '@media (max-width: 920px)': {
            gridTemplateColumns: 'repeat(1, 1fr)',
        },
    },
    gridContainerForOverview: {
        display: 'grid',
        gridTemplateColumns: 'repeat(1, 1fr)',
        '@media (max-width: 1410px)': {
            gridTemplateColumns: 'repeat(1, 1fr)',
        },
        '@media (max-width: 920px)': {
            gridTemplateColumns: 'repeat(1, 1fr)',
        },
    },
    container: {
        ...shorthands.padding('15px'),
    },

    categoryContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    category: {
        fontFamily: customFonts.TitleFont,
        color: customColors.protivitiDarkBlue,
        fontWeight: '600',
        fontSize: '18px',
        ...shorthands.padding(0, 0, 0, '1em'),
        ...shorthands.margin('5px', 0, '5px', 0),
    },
    suggestionContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    suggestionContainerInOverview: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    button: {
        width: '100%',
        whiteSpace: 'normal',
        textAlign: 'left',
        cursor: 'auto',
        color: customColors.summarizeButtonDarker,
        backgroundColor: customColors.white,
        fontSize: '15px',
        fontWeight: '500',
        fontFamily: customFonts.Lato,
        ...shorthands.padding('10px'),
        ...shorthands.margin('5px', 0, 0, 0),
        '&:hover': {
            cursor: 'pointer',
            fontWeight: '700',
        },
    },
});

interface ChatWindowBlockProps {
    category: string;
    suggestions: Record<string, Record<string, string | Record<string, string | string[]>>>;
    setValue: React.Dispatch<React.SetStateAction<string>>;
    style?: React.CSSProperties;
    accessedFromOverview?: boolean;
}

export const ChatWindowBlock: React.FC<ChatWindowBlockProps> = ({
    category,
    suggestions,
    setValue,
    style,
    accessedFromOverview,
}) => {
    const classes = useClasses();
    const dispatch = useDispatch();
    const { selectedId } = useAppSelector((state: RootState) => state.conversations);
    const chatInput = useAppSelector(
        (state: RootState) => state.currentmessages.generatingMessages[selectedId].chatInput,
        shallowEqual,
    );
    const handleOnClick = (suggestion: string) => {
        // Tracking the click event with Application Insights
        appInsights.trackEvent({
            name: 'Suggestion Clicked',
            properties: { category: 'Button', suggestion: suggestion, source: 'New Chat Session Page' },
        });

        if (suggestion === chatInput) {
            setValue('');
        } else {
            if (!accessedFromOverview) {
                setValue(suggestion);
            }
            dispatch(updateChatInput({ chatId: selectedId, text: suggestion }));
            document.body.click();
            dispatch(setSelectedTab('chat'));
        }
    };

    return category === 'Small blocks' ? (
        <div className={accessedFromOverview ? classes.gridContainerForOverview : classes.gridContainer}>
            {Object.keys(suggestions).map((category, index) => (
                <div className={classes.container} key={index}>
                    <p className={style ? '' : classes.category} style={style}>
                        {category === 'Small blocks' && !accessedFromOverview ? 'Conversation Starters' : ''}
                    </p>
                    <div
                        className={
                            accessedFromOverview ? classes.suggestionContainerInOverview : classes.suggestionContainer
                        }
                    >
                        {Object.keys(suggestions[category]).map((smallBlock, index) => {
                            if (!smallBlock || !suggestions[category][smallBlock]) {
                                return null;
                            }
                            return (
                                <button
                                    onMouseEnter={(e) => {
                                        (e.currentTarget as HTMLButtonElement).style.border = '1px solid #EA7024';
                                        (e.currentTarget as HTMLButtonElement).style.borderLeft = '5px solid #EA7024';
                                    }}
                                    onMouseLeave={(e) => {
                                        (e.currentTarget as HTMLButtonElement).style.border = '1px solid #D9D9D9';
                                        (e.currentTarget as HTMLButtonElement).style.borderLeft = '5px solid #D9D9D9';
                                    }}
                                    className={classes.button}
                                    key={index}
                                    style={{
                                        transition: 'background-color 0.3s ease',
                                        border: '1px solid #D9D9D9',
                                        borderLeft: '5px solid #D9D9D9',
                                    }}
                                    onClick={() => {
                                        handleOnClick(suggestions[category][smallBlock] as string);
                                    }}
                                >
                                    {smallBlock}
                                </button>
                            );
                        })}
                    </div>
                </div>
            ))}
        </div>
    ) : (
        <div>{/* Content for other categories can go here */}</div>
    );
};
