import { IGPT } from "../../../libs/models/GPT";

export interface GPTState {
    userGPTs: IGPT[];
    publicGPTs: IGPT[];
}

export const initialState: GPTState = {
    userGPTs: [],
    publicGPTs: [],
};