import { FC, useCallback, useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import {
    Dialog,
    DialogTrigger,
    DialogSurface,
    DialogTitle,
    DialogBody,
    DialogActions,
    DialogContent,
    Button,
    makeStyles,
    mergeClasses,
    shorthands,
} from '@fluentui/react-components';
import { AuthHelper } from '../../libs/auth/AuthHelper';
import { RootState, getStore } from '../../redux/app/store';
import SignalRHubConnection from '../../redux/features/message-relay/signalRHubConnection';
import { useMsal } from '@azure/msal-react';
import { useAppSelector } from '../../redux/app/hooks';
import { customColors, customFonts } from '../../styles';

const useClasses = makeStyles({
    root: {
        width: '450px',
        borderRadius: '0',
    },
    actions: {
        paddingTop: '10%',
    },
    continue: {
        backgroundColor: customColors.protivitiOrange,
        '&:hover': {
            backgroundColor: customColors.summarizeButtonDarker,
            color: customColors.white,
        },
    },
    logout: {
        backgroundColor: customColors.white,
        '&:hover': {
            backgroundColor: customColors.summarizeButtonDarker,
            color: customColors.white,
        },
    },
    button: {
        fontFamily: customFonts.Lato,
        fontSize: '12px',
        borderRadius: '0',
        ...shorthands.border('1.5px', 'solid', customColors.protivitiOrange),
    },
    title: {
        fontFamily: customFonts.TitleFont,
        fontWeight: '500',
        fontSize: '24px',
    },
    content: {
        fontFamily: customFonts.Lato,
    },
});
interface IdleTimerProps {
    IsLoggedOut?: boolean;
}

export const IdleTimerAlert1: FC<IdleTimerProps> = ({ IsLoggedOut }) => {
    const classes = useClasses();
    const [timeout] = useState<number>(process.env.REACT_APP_IdleTimeOut as unknown as number); //(3300000);//55minutes
    const [promptBeforeIdle] = useState<number>(120000); //last 2 minutes
    const { instance } = useMsal();
    const [state, setState] = useState<string>('Active');
    const [remaining, setRemaining] = useState<number>(timeout / 1000); //converting ms to s for initial display
    const [open, setOpen] = useState<boolean>(false);
    const { conversations, selectedId } = useAppSelector((state: RootState) => state.conversations);

    const signalRHubConnection = new SignalRHubConnection(getStore());

    const formatTime = (seconds: number) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes < 10 ? '0' : ''}${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    };

    const onIdle = () => {
        setState('Idle');
        setOpen(false);

        onLogout();
        console.log(IsLoggedOut, state);
    };

    const onActive = () => {
        setState('Active');
        setOpen(false);
    };

    const onPrompt = () => {
        setState('Prompted');
        setOpen(true);
    };

    const { getRemainingTime, reset } = useIdleTimer({
        onPrompt,
        onIdle,
        onActive,
        timeout,
        promptBeforeIdle,
        throttle: 500,
    });

    useEffect(() => {
        // set remaining time before interval starts so that time decrements on load without skipping
        setRemaining(Math.ceil(getRemainingTime() / 1000));
        const interval = setInterval(() => {
            setRemaining(Math.ceil(getRemainingTime() / 1000));
        }, 500);

        return () => {
            clearInterval(interval);
        };
    });
    const onLogout = useCallback(() => {
        setOpen(false);
        AuthHelper.logoutAsync(instance);
    }, [instance]);

    const onContinue = () => {
        setState('Active');
        setOpen(false);
        reset();
        async () => {
            await AuthHelper.ssoSilentRequest(instance);
        };
        signalRHubConnection.getOrCreateHubConnection(conversations[selectedId].gptEndpoint, 'manual');
    };

    return (
        <Dialog open={open}>
            <DialogSurface className={classes.root}>
                <DialogBody>
                    <DialogTitle className={classes.title}>Alert</DialogTitle>
                    <DialogContent className={classes.content}>Logging out in {formatTime(remaining)}</DialogContent>
                    <DialogActions>
                        <DialogTrigger disableButtonEnhancement>
                            <Button
                                style={{ transition: 'all 0.3s ease' }}
                                className={mergeClasses(classes.logout, classes.button)}
                                appearance="secondary"
                                onClick={() => {
                                    onLogout();
                                }}
                            >
                                Logout
                            </Button>
                        </DialogTrigger>
                        <Button
                            style={{ transition: 'all 0.3s ease' }}
                            className={mergeClasses(classes.continue, classes.button)}
                            onClick={() => {
                                onContinue();
                            }}
                        >
                            Continue
                        </Button>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );
};