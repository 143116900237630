import React, { useEffect, useState } from 'react';
//import Modal from 'react-modal'; // Import the Modal component
import ReactStars from 'react-rating-stars-component'; // Import the ReactStars component
import { useSubmitRating } from '../../libs/hooks/useRating';
import {  Modal, Button,Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './RatingFeedback.css';
import { customColors, customFonts } from '../../styles';

const RatingFeedback = ({ isOpen, onClose, userId, gptId, updateGpts}: {  isOpen: boolean, onClose: () => void, userId?: string, // Add userId prop
gptId?: string, updateGpts: () => Promise<void>}) => {
    const [modalIsOpen, setModalIsOpen] = useState(isOpen);
    const [rating, setRating] = useState(0);
    const [comment, setComment] = useState('');
    const { submitRating ,getRating, updateRating} = useSubmitRating();
    const [fetched, setFetched] = useState(false);

    const uploadButtonStyle = {
        fontFamily: customFonts.Lato,
        fontSize: '12px',
        border: `1px solid ${customColors.protivitiOrange}`,
        borderRadius: '0',
        padding: '5px 15px',
        margin: '0 8px 0 0', // Assuming tokens.spacingHorizontalS is '8px'
        float: 'right'  as 'right',
        color: '#000',
        background: 'transparent',
        fontWeight: '600',
    };

    const cancelButtonStyle = {
        fontFamily: customFonts.Lato,
        fontSize: '12px',
        border: '1px solid #000',
        borderRadius: '0',
        padding: '5px 45px',
        margin: '0 8px 0 0', // Assuming tokens.spacingHorizontalS is '8px'
        float: 'right'  as 'right',
        color: '#000',
        background: 'transparent',
        fontWeight: '600',
    };


    const closeModal = () => {
        setModalIsOpen(false);
        onClose();
      };


    const handleRatingChange = (newRating: number) => {
        setRating(newRating);
    };

    const handleCommentChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setComment(event.target.value);
    };

    useEffect(() => {
        setModalIsOpen(isOpen);
        if (isOpen && gptId && !fetched) {
            const fetchRating = async () => {
                const existingRating = await getRating(gptId);
                if (existingRating) {
                    setRating(existingRating.value);
                    setComment(existingRating.feedback);
                    setFetched(true);
                }
            };
            fetchRating();
        }
    }, [isOpen, getRating, gptId, fetched]);

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!userId || !gptId) {
            // Handle the case where userId or gptId is null or undefined
            console.error('userId or gptId is not defined');
            return;
        }
        const existingRating = await getRating(gptId);
        if (existingRating && Object.keys(existingRating).length !== 0) {
            await updateRating(userId, gptId, rating, comment);
            toast.success('Rating updated successfully!');
        } else {
            await submitRating(userId, gptId, rating, comment);
            toast.success('Rating submitted successfully!');
        }
        await updateGpts();
        console.log(`Rating: ${rating}, Comment: ${comment}`);
        setFetched(false);
        closeModal();
    };

    return (
      <div>
    <Modal
        show={modalIsOpen}
        onHide={closeModal}
        centered
        className="custom-modal"
    >
        <Modal.Header closeButton style={{
        border: 'none',
        fontSize: '25px',
        fontWeight: '500',
        color: customColors.summarizeButtonDarker,
        fontFamily: customFonts.TitleFont,
    }}>
            <Modal.Title>Leave Feedback</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{
        bottom: '20px',
        fontSize: '18px',
        fontWeight: '600',
        color: customColors.headerLightGray,
        fontFamily: customFonts.TitleFont,
    }}>
            <p>How well did this GPT meet your expectations?</p>
            <div style={{ position: 'relative', bottom: '22px' }}>
            <ReactStars
                key={rating}
                count={5}
                value={rating}
                onChange={handleRatingChange}
                size={48}
                activeColor="#ffd700"
                isHalf={true}
            />
            </div>
            <Form id="feedbackForm" onSubmit={handleSubmit}>
                <Form.Group controlId="feedbackForm.ControlTextarea">
                    <Form.Control
                        as="textarea"
                        rows={3}
                        value={comment}
                        onChange={handleCommentChange}
                        placeholder="Additional comments"
                        className="additional-comments"
                    />
                </Form.Group>
                <Button  type="submit" style={uploadButtonStyle} form="feedbackForm">Submit Feedback</Button>
                <Button  onClick={closeModal} style={cancelButtonStyle}>Close</Button>
            </Form>
        </Modal.Body>
    </Modal>
</div>
  );
};

export default RatingFeedback;
