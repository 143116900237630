import * as React from 'react';
import GPTOverview from '../../custom-gpt/GPTOverview';
import { IGPT } from '../../../libs/models/GPT';

interface GPTOverviewTabProps {
    gpt: IGPT | undefined;
}

export const GPTOverviewTab: React.FC<GPTOverviewTabProps> = ({ gpt }) => {
    if (gpt) {
        return <GPTOverview gpt={gpt} isModal={false} />;
    }
    return;
};