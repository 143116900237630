import { makeStyles, shorthands } from '@fluentui/react-components';
import React, { useEffect, useMemo, useState } from 'react';
import promptSuggestions from '../../../prompt-library.json';
import { customColors, customFonts, ScrollBarStyles } from '../../../styles';
import { ToolTipBlock } from './ToolTipBlock';
import { IGPT } from '../../../libs/models/GPT';
import { useAppSelector } from '../../../redux/app/hooks';
import { RootState } from '../../../redux/app/store';
import ToolTipBlockWrapper from './ToolTipBlockWrapper';

const useClasses = makeStyles({
    toolTipcontainer: {
        backgroundColor: customColors.white,
        display: 'flex',
        flexDirection: 'column',
        maxHeight: '100%',
        ...ScrollBarStyles,

        //maxHeight:'450px',
    },
    header: {
        ...shorthands.padding(0, 0, 0, '20px'),
        fontWeight: 'bold',
        fontSize: '18px',
        fontFamily: customFonts.Lato,
    },
    toolTipRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    largeBlockContainer: {
        ...shorthands.padding('20px', 0, 0, 0),
    },
    suggestionContainer: {
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        '@media (max-width: 850px)': {
            gridTemplateColumns: 'repeat(2, 1fr)',
        },
        // '@media (max-width: 700px)' : {
        //     gridTemplateColumns: 'repeat(1, 1fr)',
        // }
    },
});

interface PopoverContentProps {
    setValue: React.Dispatch<React.SetStateAction<string>>;
    isDocumentChat: boolean;
    selectedId: string;
}

export const PopoverContent = ({ setValue, isDocumentChat, selectedId }: PopoverContentProps) => {
    const classes = useClasses();
    const _chatType = isDocumentChat ? 'Document' : 'Regular';
    const { conversations } = useAppSelector((state: RootState) => state.conversations);
    const [selectedGPT, setSelectedGPT] = useState<IGPT | undefined>(undefined);
    const { userGPTs } = useAppSelector((state: RootState) => state.gpts);

    const activeGPT = useMemo(() => {
        return userGPTs.find((gpt) => gpt.gptEndpoint === conversations[selectedId]?.gptEndpoint);
    }, [selectedId, userGPTs]);

    useEffect(() => {
        setSelectedGPT(activeGPT);
    }, [activeGPT]);

    return (
        <div className={classes.toolTipcontainer}>
            <div style={{ padding: '20px' }}>
                {selectedGPT?.id === process.env.REACT_APP_DEFAULT_PROGPT ? (
                    Object.keys(promptSuggestions).map((chatType) => {
                        if (chatType === _chatType) {
                            const categorySuggestions = promptSuggestions[chatType as keyof typeof promptSuggestions];

                            return Object.keys(categorySuggestions).map((childCategory, childIndex) => {
                                const suggestions =
                                    categorySuggestions[childCategory as keyof typeof categorySuggestions];

                                return (
                                    <ToolTipBlock
                                        key={childIndex}
                                        category={childCategory}
                                        suggestions={suggestions}
                                        setValue={setValue}
                                    />
                                );
                            });
                        } else {
                            return <></>;
                        }
                    })
                ) : (
                    <ToolTipBlockWrapper promptLibrary={selectedGPT?.promptLibrary} setValue={setValue} />
                )}
            </div>
        </div>
    );
};
