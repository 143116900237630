import React from 'react';
import { useState } from 'react';
import { Button, Tooltip } from '@fluentui/react-components';
import { Copy16Regular, Checkmark16Regular } from '@fluentui/react-icons';
import { shorthands, makeStyles } from '@fluentui/react-components';

const useClasses = makeStyles({
    container: {
        ...shorthands.padding(0)
    },
    checkMark: {
        color: 'green',
        fontWeight: 'bold',
    },
});

interface CopyChatButtonProps {
    message: string;
}

export const CopyChatButton: React.FC<CopyChatButtonProps> = ({ message }) => {
    const [copySuccess, setCopySuccess] = useState(false);
    const [hover, setHover] = useState(false);
    const classes = useClasses();

    const formatTable = (tableString: string) => {
        const rows = tableString.split('\n');
        const pre = []; // Content before table
        const table = []; // Table to be formatted
        const post = []; // Content after table

        let tableStarted = false;
        for (const row of rows) {
            if (!row?.includes('|')) {
                if (!tableStarted) {
                    pre.push(row);
                } else {
                    post.push(row);
                }
            } else {
                tableStarted = true;
                table.push(row);
            }
        }

        if (table.length >= 2) {
            table.splice(1, 1);
        }

        const formattedTable = table.map(
            (row) =>
                row
                    ?.replace(/^\s*\|\s*|\s*\|\s*$/g, '') // Remove leading and trailing | and whitespace
                    ?.replace(/\s*\|\s*/g, '\t'), // Replace | with tabs
        );

        const formattedTableString = [...pre, ...formattedTable, ...post].join('\n');
        return formattedTableString;
    };

    const handleCopyToClipboard = () => {
        const contentToCopy: string = message;

        const transformedPlainTextString = contentToCopy
            ?.replace(/\*\*(.*?)\*\*/g, '$1') // Remove **
            ?.replace(/\n/g, '\n'); // Keep line breaks as they are

        const transformedHtmlString = contentToCopy
            ?.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>') // HTML formatting for bold
            ?.replace(/\n/g, '<br>'); // HTML formatting for line breaks

        // If there is a table
        if (contentToCopy?.includes('|')) {
            const formattedTable = formatTable(transformedPlainTextString);
            navigator.clipboard
                .write([
                    new window.ClipboardItem({
                        'text/plain': new Blob([formattedTable], {
                            type: 'text/plain',
                        }),
                    }),
                ])
                .then(() => {
                    setCopySuccess(true);

                    setTimeout(() => {
                        setCopySuccess(false);
                    }, 2000);
                })
                .catch((error) => {
                    console.log(error);
                    setCopySuccess(false);
                });
        }
        // If there is not table
        else {
            navigator.clipboard
                .write([
                    new window.ClipboardItem({
                        'text/plain': new Blob([transformedPlainTextString], {
                            type: 'text/plain',
                        }),
                        'text/html': new Blob([transformedHtmlString], {
                            type: 'text/html',
                        }),
                    }),
                ])
                .then(() => {
                    setCopySuccess(true);

                    setTimeout(() => {
                        setCopySuccess(false);
                    }, 2000);
                })
                .catch((error) => {
                    console.log(error);
                    setCopySuccess(false);
                });
        }
    };

    const handleMouseOver = (e: React.MouseEvent) => {
        e.preventDefault();
        setHover(true);
    };

    const handleMouseLeave = (e: React.MouseEvent) => {
        e.preventDefault();
        setHover(false);
    };

    return (
        <div className={classes.container}>
            <div
                style={{
                    backgroundColor: hover ? '#f2f2f2' : 'transparent',
                    borderRadius: '5px',
                    transition: 'background-color 0.3s ease-in-out',
                }}
            >
                <Tooltip content="Copy" relationship="label">
                    <Button
                        icon={copySuccess ? <Checkmark16Regular className={classes.checkMark} /> : <Copy16Regular />}
                        style={{ border: 'none' }}
                        appearance="transparent"
                        onClick={handleCopyToClipboard}
                        onMouseOver={handleMouseOver}
                        onMouseLeave={handleMouseLeave}
                    ></Button>
                </Tooltip>
            </div>
        </div>
    );
};
