import { Button } from '@fluentui/react-button';
import { makeStyles, mergeClasses, shorthands, tokens } from '@fluentui/react-components';
import { Checkmark20Filled, Dismiss20Filled } from '@fluentui/react-icons';
import { Input, InputOnChangeData } from '@fluentui/react-input';
import { useEffect, useState } from 'react';
import { useChat } from '../../../libs/hooks';
import { useAppDispatch, useAppSelector } from '../../../redux/app/hooks';
import { RootState } from '../../../redux/app/store';
import { editConversationTitle } from '../../../redux/features/conversations/conversationsSlice';
import { Breakpoints, customColors, customFonts } from '../../../styles';
import { toast } from 'react-toastify';

const useClasses = makeStyles({
    root: {
        width: '100%',
        ...Breakpoints.small({
            display: 'none',
        }),
    },
    buttons: {
        display: 'flex',
        alignSelf: 'end',
        width: '50px'
    },
    textButtons: {
        ...shorthands.gap(tokens.spacingVerticalS),
    },
    input: {
        width: 'max(120px, 60%)'
    },
    toastMessage: {
        fontSize: '18px',
        color: 'black',
        fontFamily: customFonts.TitleFont,
        backgroundColor: '#F7F7F8',
        border: "1px solid black",
        marginTop: '33px',
    },
});

interface IEditChatNameProps {
    name: string;
    chatId: string;
    exitEdits: () => void;
    textButtons?: boolean;
}

export const EditChatName: React.FC<IEditChatNameProps> = ({ name, chatId, exitEdits, textButtons }) => {
    const classes = useClasses();
    const dispatch = useAppDispatch();
    const { conversations, selectedId } = useAppSelector((state: RootState) => state.conversations);
    const chat = useChat();

    const [title = '', setTitle] = useState<string | undefined>(name);

    useEffect(() => {
        if (selectedId !== chatId) exitEdits();
    }, [chatId, exitEdits, selectedId]);

    const onSaveTitleChange = async () => {
        if (name !== title) {
            const chatState = conversations[selectedId];
            await chat
                .editChat(
                    chatId,
                    title,
                    chatState.chatType ? true : false,
                    chatState.systemDescription,
                    chatState.memoryBalance,
                    chatState.gptEndpoint,
                )
                .then(() => {
                    dispatch(editConversationTitle({ id: chatId, newTitle: title }));
                });
        }
        exitEdits();
    };

    const onClose = () => {
        setTitle(name);
        exitEdits();
    };

    const onTitleChange = (_ev: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => {
        setTitle(data.value);
    };

    const handleSave = () => {
        onSaveTitleChange().catch((e: any) => {
            const errorMessage = `Unable to retrieve chat to change title. Details: ${
                e instanceof Error ? e.message : String(e)
            }`;
            toast.error(errorMessage, {
                className: classes.toastMessage,
              });
        });
    };

    const handleKeyDown: React.KeyboardEventHandler<HTMLElement> = (event) => {
        if (event.which == 32) {
            event.key = '&nbsp;';
        }
        if (event.key === 'Enter') {
            handleSave();
        }
    };
    return (
        <div
            className={classes.root}
            style={{
                display: 'flex',
                flexDirection: `${textButtons ? 'column' : 'row'}`,
                gap: `${textButtons ? tokens.spacingVerticalS : tokens.spacingVerticalNone}`,
            }}
        >
            <Input className={classes.input} value={title} onChange={onTitleChange} id={title} onKeyDown={handleKeyDown} />
            {textButtons && (
                <div className={mergeClasses(classes.buttons, classes.textButtons)}>
                    <Button appearance="secondary" onClick={onClose}>
                        Cancel
                    </Button>
                    <Button type="submit" appearance="primary" onClick={handleSave}>
                        Save
                    </Button>
                </div>
            )}
            {!textButtons && (
                <div className={classes.buttons}>
                    <Button aria-label= 'edit_name_cancel' icon={<Dismiss20Filled primaryFill={customColors.white}  />} onClick={onClose} appearance="transparent" />
                    <Button aria-label = 'edit_name_confirm' icon={<Checkmark20Filled primaryFill={customColors.white} />} onClick={handleSave} appearance="transparent" />
                </div>
            )}
        </div>
    );
};
