import { makeStyles, shorthands, tokens } from '@fluentui/react-components';
import { GetResponseOptions, useChat } from '../../libs/hooks/useChat';
import { AuthorRoles, IChatMessage } from '../../libs/models/ChatMessage';
import { useAppDispatch, useAppSelector } from '../../redux/app/hooks';
import { RootState } from '../../redux/app/store';
import { addMessageToConversationFromUser } from '../../redux/features/conversations/conversationsSlice';
import { Breakpoints, SharedStyles, customFonts, customTokens } from '../../styles';
import Loading from '../views/Loading';
import { ChatInput } from './ChatInput';
import { ChatHistory } from './chat-history/ChatHistory';
import { ChatWindowScreen } from './prompt-suggestion/ChatWindowScreen';
import React, { useEffect, useMemo, useState } from 'react';
import { IGPT } from '../../libs/models/GPT';

const useClasses = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
        width: '100%',
        fontFamily: customFonts.Lato,
        ...shorthands.overflow('hidden'),
        ...Breakpoints.small({
            width: '100%',
        }),
    },
    scroll: {
        ...shorthands.margin(tokens.spacingVerticalXS),
        ...SharedStyles.scroll,
    },
    history: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',

        ...Breakpoints.small({
            justifyContent: 'center',
            marginLeft: 0,
            marginRight: 0,
            maxWidth: '100%',
            overflowX: 'hidden',
        }),
    },
    input: {
        marginTop: '15px',
        alignSelf: 'center',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        ...Breakpoints.small({
            ...shorthands.padding('0'),
            width: '100%',
        }),
    },
    singleCitationLink: {
        textDecorationLine: 'none',
        color: customTokens.colorBrandBackgroundSelected,
        cursor: 'pointer',
    },
    followUpRow: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: '10px',
        ...shorthands.gap(customTokens.spacingHorizontalS),
        marginLeft: '10%',
        width: '90%',
        alignContent: 'flex-start',
    },
    singleCitation: {
        marginBottom: '0px',
        fontSize: '12px',
        fontWeight: '500',
        fontFamily: customFonts.Lato,
        ...shorthands.padding('1px', '7px'),
        ...shorthands.borderRadius(customTokens.borderRadiusMedium),
        ...shorthands.border('1px', 'solid', 'rgb(0, 64, 104)'),
        color: 'rgb(0, 64, 104)',
    },
    icon: {
        width: '20px',
        height: 'auto',
    },
});

export const ChatRoom: React.FC = () => {
    const { conversations, selectedId } = useAppSelector((state: RootState) => state.conversations);
    const { activeUserInfo } = useAppSelector((state: RootState) => state.app);
    const [value, setValue] = React.useState('');

    const chat = useChat();
    const dispatch = useAppDispatch();
    const messages = conversations[selectedId].messages;
    const classes = useClasses();
    const scrollViewTargetRef = React.useRef<HTMLDivElement>(null);
    const [shouldAutoScroll, setShouldAutoScroll] = React.useState(true);

    const [isDraggingOver, setIsDraggingOver] = React.useState(false);
    const [abortController, setAbortController] = React.useState(new AbortController());
    const [loading, setLoading] = useState(false);
    const { userGPTs } = useAppSelector((state: RootState) => state.gpts);
    const [selectedGPT, setSelectedGPT] = useState<IGPT>();
    const activeGPT = useMemo(() => {
        return userGPTs.find((gpt) => gpt.gptEndpoint === conversations[selectedId]?.gptEndpoint);
    }, [selectedId, userGPTs]);

    useEffect(() => {
        setSelectedGPT(activeGPT);
    }, [activeGPT]);

    const isDocumentChat = conversations[selectedId].chatType ?? selectedGPT?.isDocumentUploadEnabled;

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            if (messages.length === 0) {
                setLoading(true);
            }
        }, 15000);
        return () => {
            setLoading(false);
            clearTimeout(timeoutId);
        };
    }, [messages]);

    const onDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
        if (!isDocumentChat) {
            return;
        }
        e.preventDefault();
        const isOverTextArea = e.target instanceof HTMLTextAreaElement;
        setIsDraggingOver(isOverTextArea);
    };

    const onDragLeave = (e: React.DragEvent<HTMLDivElement | HTMLTextAreaElement>) => {
        e.preventDefault();
        setIsDraggingOver(false);
    };

    React.useEffect(() => {
        if (!shouldAutoScroll) return;
        scrollViewTargetRef.current?.scrollTo(0, scrollViewTargetRef.current.scrollHeight);
    }, [conversations, messages, shouldAutoScroll, selectedId]);

    React.useEffect(() => {
        const onScroll = () => {
            if (!scrollViewTargetRef.current) return;
            const { scrollTop, scrollHeight, clientHeight } = scrollViewTargetRef.current;
            const isAtBottom = scrollTop + clientHeight >= scrollHeight - 10;
            setShouldAutoScroll(isAtBottom);
        };

        if (!scrollViewTargetRef.current) return;

        const currentScrollViewTarget = scrollViewTargetRef.current;

        currentScrollViewTarget.addEventListener('scroll', onScroll);
        return () => {
            currentScrollViewTarget.removeEventListener('scroll', onScroll);
        };
    }, []);

    const handleSubmit = async (options: GetResponseOptions, signal?: AbortSignal) => {
        const chatInput: IChatMessage = {
            chatId: selectedId,
            timestamp: new Date().getTime(),
            userId: activeUserInfo?.id as string,
            userName: activeUserInfo?.username as string,
            content: options.value,
            type: options.messageType,
            authorRole: AuthorRoles.User,
            threadRunConfig: conversations[selectedId].threadRunConfig,
        };

        dispatch(addMessageToConversationFromUser({ message: chatInput, chatId: selectedId }));
        await chat.getResponse(options, signal);
        setShouldAutoScroll(true);
    };

    return (
        <>
            {/* {loading === true ? (
                <div
                    style={{
                        fontWeight: 'bold',
                        fontSize: '20px',
                        color: 'black',
                        textAlign: 'center',
                        marginTop: '10%',
                    }}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
                        <path
                            fill="#cf2020"
                            d="M16.408 4.172c-1.045-1.896-3.77-1.896-4.815 0L2.35 20.92c-1.011 1.833.314 4.078 2.408 4.078H23.24c2.093 0 3.42-2.245 2.408-4.078zM15 20a1 1 0 1 1-2 0a1 1 0 0 1 2 0m-1.75-3.25v-6.5a.75.75 0 0 1 1.5 0v6.5a.75.75 0 0 1-1.5 0"
                        />
                    </svg>
                    This GPT is currently unavailable. Please try a different GPT or select a new one from Explore GPT
                </div>
            ) : loading === false && messages.length === 0 ? ( */}
            {loading === false && messages.length === 0 ? (
                <Loading text="Loading messages..." />
            ) : (
                <div
                    className={classes.root}
                    onDragEnter={onDragEnter}
                    onDragOver={onDragEnter}
                    onDragLeave={onDragLeave}
                >
                    <div ref={scrollViewTargetRef} className={classes.scroll}>
                        <div className={classes.history}>
                            {messages.length === 1 && !conversations[selectedId].chatType ? (
                                <>
                                    <ChatWindowScreen setValue={setValue} isDocumentChat={isDocumentChat} />
                                </>
                            ) : (
                                <ChatHistory
                                    setShouldAutoScroll={setShouldAutoScroll}
                                    scrollViewTargetRef={scrollViewTargetRef}
                                    onGetResponse={handleSubmit}
                                    setAbortController={setAbortController}
                                />
                            )}
                        </div>
                    </div>

                    <div className={classes.input}>
                        <ChatInput
                            isDraggingOver={isDraggingOver}
                            onDragLeave={onDragLeave}
                            onSubmit={handleSubmit}
                            value={value}
                            setValue={setValue}
                            abortController={abortController}
                            setAbortController={setAbortController}
                        />
                    </div>
                </div>
            )}
        </>
    );
};
