import {
    Button,
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTrigger,
    Tooltip,
    makeStyles,
    shorthands,
} from '@fluentui/react-components';
import {
    ArrowDownload16Regular,
    ArrowReset20Regular,
    Checkmark16Regular,
    Copy16Regular,
    DataHistogram16Regular,
} from '@fluentui/react-icons';
import Panzoom from '@panzoom/panzoom';
import mermaid from 'mermaid';
import React from 'react';
import * as svg from 'save-svg-as-png';
import { useAppSelector } from '../../../../redux/app/hooks';
import { RootState } from '../../../../redux/app/store';
import { customColors, customFonts } from '../../../../styles';
import { shallowEqual } from 'react-redux';

const useClasses = makeStyles({
    dialogSurface: {
        maxWidth: 'fit-content',
        width: '90vw',
        ...shorthands.borderRadius('1rem'),
    },
    dialogContent: {
        maxHeight: '50rem',
        paddingRight: '2.5rem',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    container: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-between',
        '@media (max-width: 670px)': {
            display: 'flex',
            flexDirection: 'column',
        },
    },
    panzoomContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        '@media (max-width: 670px)': {
            visibility: 'hidden',
        },
    },
    rangeInputContainer: {
        ...shorthands.padding(0, 0, 0, '5px'),
    },
    rangeInput: {
        ...shorthands.padding(0, 0, 0, '10px'),
    },
    slider: {
        accentColor: '#004068',
        '&: hover': {
            cursor: ' pointer',
        },
    },
    downloadContainer: {
        '@media (max-width: 100vw) and (min-width: 671px)': {
            ...shorthands.padding(0, '4em', 0, 0),
        },
        '@media (max-width: 670px) and (min-width: 501px)': {
            ...shorthands.padding(0, '2em', 0, 0),
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
        },
        '@media (max-width: 500px)': {
            ...shorthands.padding(0),
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
        },
    },
    checkMark: {
        color: 'green',
        fontWeight: 'bold',
    },
    noteContainer: {
        display: 'flex',
        alignItems: 'center',
        fontFamily: customFonts.Lato,
        fontSize: '13px',
        fontWeight: '800',
        color: customColors.protivitiDarkBlue,
        '@media (max-width: 1200px)': {
            fontSize: '11.5px',
        },
        '@media (max-width: 500px)': {
            fontSize: '11px',
        },
    },
    contentContainer: {
        width: '90vw',
        height: '70vh',
    },
    svgContainer: {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
});

interface DiagramDialogProps {
    graphDefinition: string;
}

export const DiagramDialog = ({ graphDefinition }: DiagramDialogProps) => {
    const classes = useClasses();
    const { selectedId } = useAppSelector((state: RootState) => state.conversations);
    const [flag, setFlag] = React.useState(false);
    const [zoomScale, setZoomScale] = React.useState(0);
    const [parentWidth, setParentWidth] = React.useState(0);
    const [open, setOpen] = React.useState(false);
    const [copySuccess, setCopySuccess] = React.useState(false);
    const [svgHtmlElement, setSvgHtmlElement] = React.useState<HTMLElement>();
    const [svgString, setSVGString] = React.useState('');
    const isResponseGenerating = useAppSelector(
        (state: RootState) => state.currentmessages.generatingMessages[selectedId]?.isResponseGenerating,
        shallowEqual,
    );

    const childRef = React.useRef<HTMLDivElement | null>(null);
    const containerRef = React.useRef<HTMLDivElement | null>(null);
    const resetButtonRef = React.useRef<HTMLButtonElement | null>(null);
    const rangeInputRef = React.useRef<HTMLInputElement | null>(null);

    const downloadSVG = () => {
        const svgBlob = new Blob([svgString], { type: 'image/svg+xml;charset=utf-8' });
        const svgUrl = URL.createObjectURL(svgBlob);
        const downloadLink = document.createElement('a');
        downloadLink.href = svgUrl;
        downloadLink.download = 'diagram.svg';
        document.body.appendChild(downloadLink);
        downloadLink.click();
    };

    const downloadPNG = () => {
        svg.saveSvgAsPng(svgHtmlElement as svg.SourceElement, 'diagram.png', { backgroundColor: 'white' });
    };

    const copyToClipboard = () => {
        svg.svgAsPngUri(svgHtmlElement as svg.SourceElement, { backgroundColor: 'white' })
            .then((pngUri) => {
                const img = new Image();
                img.src = pngUri;
                img.onload = () => {
                    const canvas = document.createElement('canvas');
                    canvas.width = img.width;
                    canvas.height = img.height;
                    const ctx = canvas.getContext('2d');
                    if (!ctx) {
                        console.error('Canvas context not supported');
                        return;
                    }
                    ctx.drawImage(img, 0, 0, img.width, img.height);

                    canvas.toBlob((blob) => {
                        if (blob) {
                            (async () => {
                                const clipboardItem = new ClipboardItem({ 'image/png': blob });
                                await navigator.clipboard.write([clipboardItem]);
                            })()
                                .then(() => {
                                    setCopySuccess(true);

                                    setTimeout(() => {
                                        setCopySuccess(false);
                                    }, 2000);
                                })
                                .catch((error) => {
                                    console.error('Error copying to clipboard: ', error);
                                    setCopySuccess(false);
                                });
                        }
                    }, 'image/png');
                };
            })
            .catch((error: any) => {
                console.error('Error getting png uri: ', error);
            });
    };

    React.useEffect(() => {
        const validateGraphDefinition = async () => {
            return await mermaid.parse(graphDefinition);
        };

        validateGraphDefinition()
            .then(() => {
                setFlag(true);
            })
            .catch((_) => {
                setFlag(false);
                // console.error('Invalid graph definition: ', error)
            });
        // eslint-disable-next-line
    }, []);

    React.useEffect(() => {
        if (open) {
            const updateWidth = () => {
                if (childRef.current?.parentElement) {
                    const parentElement = childRef.current.parentElement as HTMLDivElement;
                    setParentWidth(parentElement.offsetWidth);
                }
            };
            updateWidth();
        }
    }, [open]);

    React.useEffect(() => {
        const renderImageToDOM = async () => {
            const { svg } = await mermaid.render('mermaid', graphDefinition);
            const parser = new DOMParser();
            const svgDoc = parser.parseFromString(svg, 'image/svg+xml');
            const svgElement = svgDoc.documentElement;
            const svgString = svgDoc.documentElement.outerHTML;
            setSVGString(svgString);
            setSvgHtmlElement(svgElement);
        };

        if (open) {
            renderImageToDOM().catch((error) => {
                console.error('Error while fetching SVG: ', error);
            });
        }

        // eslint-disable-next-line
    }, [open]);

    React.useEffect(() => {
        if (open) {
            const area = containerRef.current;
            if (area) {
                const panzoom = Panzoom(area, {
                    cursor: 'pointer',
                });
                if (Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0) <= 670) {
                    setZoomScale(0.8);
                    panzoom.zoom(0.8);
                }
                setZoomScale(panzoom.getScale());
                const parent = area.parentElement;
                parent?.addEventListener('wheel', (event) => {
                    panzoom.zoomWithWheel(event);
                    setZoomScale(panzoom.getScale());
                });

                resetButtonRef.current?.addEventListener('click', (event) => {
                    panzoom.reset(event);
                    setZoomScale(panzoom.getScale());
                });

                rangeInputRef.current?.addEventListener('input', (event) => {
                    if (event.target instanceof HTMLInputElement) {
                        panzoom.zoom(event.target.valueAsNumber);
                        setZoomScale(panzoom.getScale());
                    }
                });
            }
        }
    }, [open]);

    return (
        <Dialog
            open={open}
            onOpenChange={(_, data) => {
                setOpen(data.open);
            }}
        >
            <DialogTrigger disableButtonEnhancement>
                <Tooltip
                    content={
                        isResponseGenerating ? 'Please wait!' : !flag ? 'Not Valid Mermaid Syntax!' : 'View Diagram'
                    }
                    relationship="label"
                >
                    <Button icon={<DataHistogram16Regular />} disabled={!flag || isResponseGenerating}></Button>
                </Tooltip>
            </DialogTrigger>
            <DialogSurface className={classes.dialogSurface}>
                <DialogBody>
                    <DialogContent className={classes.dialogContent}>
                        <div className={classes.contentContainer}>
                            <div
                                ref={containerRef}
                                className={classes.svgContainer}
                                dangerouslySetInnerHTML={{ __html: svgString }}
                            ></div>
                        </div>
                    </DialogContent>
                    <DialogActions style={{ width: parentWidth }} ref={childRef}>
                        <div className={classes.container}>
                            <div className={classes.panzoomContainer}>
                                <Tooltip content="Reset Zoom" relationship="label">
                                    <Button ref={resetButtonRef} icon={<ArrowReset20Regular />}></Button>
                                </Tooltip>
                                <div className={classes.rangeInputContainer}>
                                    <input
                                        ref={rangeInputRef}
                                        type="range"
                                        min="0.1"
                                        max="4"
                                        step="0.1"
                                        value={zoomScale}
                                        className={classes.slider}
                                        onChange={() => {}}
                                    ></input>
                                </div>
                            </div>
                            <div className={classes.noteContainer}>
                                Note: The diagram presented is a draft. Review and refinement may be required.
                            </div>

                            <div className={classes.downloadContainer}>
                                <Tooltip content="Copy to clipboard" relationship="label">
                                    <Button
                                        icon={
                                            copySuccess ? (
                                                <Checkmark16Regular className={classes.checkMark} />
                                            ) : (
                                                <Copy16Regular />
                                            )
                                        }
                                        onClick={() => {
                                            copyToClipboard();
                                        }}
                                    ></Button>
                                </Tooltip>

                                <Tooltip content="Download as SVG" relationship="label">
                                    <Button
                                        icon={<ArrowDownload16Regular />}
                                        onClick={() => {
                                            downloadSVG();
                                        }}
                                    >
                                        SVG
                                    </Button>
                                </Tooltip>

                                <Tooltip content="Download as PNG" relationship="label">
                                    <Button
                                        icon={<ArrowDownload16Regular />}
                                        onClick={() => {
                                            downloadPNG();
                                        }}
                                    >
                                        PNG
                                    </Button>
                                </Tooltip>

                                <DialogTrigger disableButtonEnhancement>
                                    <Button>Close</Button>
                                </DialogTrigger>
                            </div>
                        </div>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );
};
